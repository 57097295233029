import React from "react";
import { Link } from "react-router-dom";




function NotFound(args) {

    return (
        <>
        <main className="grid min-h-full place-items-center bg-gradient-to-b from-[#eaf3f2] to-[#1cf36c12]to-blue-500 px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">🤔 <br />Oops la page demandée n'existe pas</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">Désole, il semblerait que la page n'existe pas, ou se trouve à une autre adresse.</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link to="/">
          <button className="btn-primary">Retour à l'accueil</button>
            </Link>
          </div>
        </div>
      </main>
        </>
    );
}

export default NotFound;