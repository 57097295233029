import React, { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
//component 
import Listing from "./Listing";
import NavVente from "./NavVente";
import FooterVente from "./FooterVente";
import { Dialog } from '@headlessui/react';
import ContentModalDiagGes from "../components/annonce/ModalDiagGes";
import ContentModalDiagDpe from "../components/annonce/ModalDiagDpe";
import CardAcheteur from "../blog/CardClotereAcheteurAnnonce";
//img et icn
import { CheckCircleIcon, ArrowsPointingOutIcon, CurrencyEuroIcon, InformationCircleIcon, HomeIcon, ChevronRightIcon, QuestionMarkCircleIcon, MapPinIcon, DocumentCurrencyEuroIcon, ArrowUpRightIcon,XMarkIcon } from "@heroicons/react/24/solid";
import Loading from '../components/Loading';
import AlertComponent from '../components/Alert';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const hoverPhoto = (args) => {
    return (
        <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center hidden group-hover:block">
            <a href={args} target="blank" className="btn btn-icon bg-green-600 hover:bg-green-700 text-white rounded-full lightbox">
                <ArrowsPointingOutIcon className="w-8 h-8" />
            </a>
        </div>
    );
};



function Annonce() {
    const [openClasse, setOpenClasse] = useState(false);
    const [openGes, setOpenGes] = useState(false);
    const [annonce, setAnnonce] = useState(null);
    const [messageSucces, setMessageSucces] = useState(false);
    const [messageErrorRep, setMessageErrorRep] = useState(false);
    
    const { region } = useParams();
    const { id } = useParams();
    console.log("params", id, region);

    useEffect(() => {
        //on recupere la data de l'annonce
        if (id !== null) {
            const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/annonce_2/${id}`;

            fetch(
                URL,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                        'content-type': 'application/x-www-form-urlencoded',
                        "Accept": "application/json, text/plain, /"
                    },
                })
                .then((res) => res.json())
                .then((res) => {
                    //console.log("this annonce", res);
                    setAnnonce(res.fields);
                })
                .catch((error) => console.log(error));
        }
    }, []);

    const handleContact = (event) => {
        event.preventDefault();
        console.log("envoyer");
        const email = event.target.email.value;
        const telephone = event.target.telephone.value;
        const message = event.target.message.value;
        const reponse = event.target.calcul.value;
        const emailVendeur =  annonce.email_from_user;
        const idAnnonce = id;
        
        //controle de la réponse
        if(reponse !== "4"){
            setMessageErrorRep(true);
        }else{
            const sendToZapier = async () => {
                const zapierURL = "https://hooks.zapier.com/hooks/catch/7206204/286wr9h/"; // 👈 Specify your Zapier Webhook URL here
                try {
                  const response = await fetch(zapierURL, {
                    method: "POST",
                    mode: "cors",
                    body: JSON.stringify({email,telephone,message,idAnnonce,emailVendeur})
                  });
                  const res = await response.json();
                  console.log(res);
                  if(res){
                    setMessageErrorRep(false);
                    setMessageSucces(true);
                  }
                } catch (e) {
                  console.log(e);
                }
              };
              sendToZapier();
        }
        
    }

    return (
        <>
            {annonce && <>

                <div className="bg-white">

                    <NavVente />
                    {/** photos */}
                    <section className="relative md:pb-24 pb-6">
                        <div className="container-fluid">
                            <div className="md:flex mt-4">
                                {/** big image  */}
                                <div className="lg:w-1/2 md:w-1/2 p-1">
                                    <div className="group relative overflow-hidden sm:h-[500px]">
                                        <img src={annonce.photos[0].url} className='object-fill'/>
                                        <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                                        {hoverPhoto(annonce.photos[0].url)}
                                        <div className="sm:hidden absolute bottom-0 bg-indigo-50 p-3"><p className='text-sm font-bold mb-0'>{annonce.photo}  16photo(s)</p></div>
                                    </div>
                                </div>
                                {/** petites images */}
                                <div className="hidden sm:block  sm:w-1/2 h-[500px] overflow-hidden">
                                    <div className="flex">
                                        {annonce.photos[1] && <>
                                            <div className="w-1/2 p-1 h-[250px] overflow-hidden bg-slate-50 ">
                                                <div className="group relative overflow-hidden h-[250px]">
                                                    <img src={annonce.photos[1].url} className='w-auto min-w-[100%] min-h-[250px] absolute top-[-30%]' />
                                                    <div className="h-[250px] absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                                                    {hoverPhoto(annonce.photos[1].url)}
                                                </div>
                                            </div>
                                        </>}
                                        {annonce.photos[2] && <>
                                            <div className="w-1/2 p-1 h-[250px] overflow-hidden bg-slate-50">
                                                <div className="group relative overflow-hidden h-[250px]">
                                                    <img src={annonce.photos[2].url} className='w-auto min-w-[100%] min-h-[250px] absolute top-[-30%]' />
                                                    <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                                                    {hoverPhoto(annonce.photos[2].url)}
                                                </div>
                                            </div>
                                        </>}

                                    </div>
                                    <div className="flex">
                                        {annonce.photos[3] && <>
                                            <div className="w-1/2 p-1 h-[250px] overflow-hidden  bg-slate-50">
                                                <div className="group relative overflow-hidden h-[250px]">
                                                    <img src={annonce.photos[3].url} className='w-auto min-w-[100%] min-h-[250px] absolute top-[-30%]' />
                                                    <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                                                    {hoverPhoto(annonce.photos[3].url)}
                                                </div>
                                            </div>
                                        </>}
                                        {annonce.photos[4] && <>
                                            <div className="w-1/2 p-1 h-[250px] overflow-hidden bg-slate-50">
                                                <div className="group relative overflow-hidden h-[250px]">
                                                    <img src={annonce.photos[4].url} className='w-auto min-w-[100%] min-h-[250px] absolute top-[-30%]' />
                                                    <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                                                    {hoverPhoto(annonce.photos[4].url)}
                                                </div>
                                            </div>
                                        </>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/** content */}
                    <div className="container">
                        <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">

                            {/** info bien */}
                            <div className="lg:col-span-8 md:col-span-7">
                                <h1 className="text-3xl font-medium">{annonce.titre}</h1>
                                <ul className="py-6 mb-0 px-0 dark:border-gray-800 flex flex-col gap-3 sm:gap-0 sm:flex-row sm:items-center justify-start list-none">
                                    <li className="flex items-center  justify-start basis-1/3 text-xl">
                                        <CurrencyEuroIcon className="text-emerald-300 mr-1 size-6" />
                                        <span>{annonce.prix} €</span>
                                    </li>
                                    <li className="flex items-center  justify-start basis-1/3  text-xl">
                                        <ArrowsPointingOutIcon className="text-emerald-300 mr-1 size-6" />
                                        <span>{annonce.surface} m2</span>
                                    </li>
                                    <li className="flex items-center basis-1/3 justify-start  text-xl">
                                        <HomeIcon className="text-emerald-300 mr-1 size-6" />
                                        <span>{annonce.nb_chambre} chambre(s)</span>
                                    </li>
                                    <li className="flex items-center basis-1/3 justify-start  text-xl">
                                        <InformationCircleIcon className="text-emerald-300 mr-1 size-6" />
                                        <span>{annonce.nb_piece} piece(s)</span>
                                    </li>
                                </ul>
                                <p className="mt-8 mb-8 text-slate-600 text-lg">
                                    {annonce.description}
                                </p>
                                <div className="flex mt-3 mb-3 items-center gap-3">
                                    <div className="rounded-md bg-indigo-50 p-3">
                                        <MapPinIcon aria-hidden="true" className="size-6 text-indigo-800" />
                                    </div>
                                    <p className="text-lg mb-0"> Ce bien se situe dans la ville de <a href="#" className="underline decoration-4 text-indigo-600">{annonce.ville}, {annonce.code_postal}</a> </p>
                                </div>

                                {/** caractéristiques */}
                                <div className="mt-8 mb-8 border-t-2  border-slate-50 pt-10">
                                    <p className="text-xl font-bold">Caractéristiques de ce logement</p>
                                    <dl className="mt-5 grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3">
                                        <div className="relative overflow-hidden">
                                            <dt>
                                                <div className="absolute rounded-md bg-indigo-50 p-3">
                                                    <ChevronRightIcon aria-hidden="true" className="size-6 text-indigo-800" />
                                                </div>
                                                <p className="ml-16 truncate text-sm font-medium text-gray-500 mb-0">Type</p>
                                            </dt>
                                            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                                                <p className="text-xl font-semibold text-gray-900 mb-0">{annonce.type}</p>
                                            </dd>
                                        </div>
                                        {annonce.ascenceur === "oui" && <>
                                            <div className="relative overflow-hidden">
                                            <dt>
                                                <div className="absolute rounded-md bg-indigo-50 p-3">
                                                    <ChevronRightIcon aria-hidden="true" className="size-6 text-indigo-800" />
                                                </div>
                                                <p className="ml-16 truncate text-sm font-medium text-gray-500 mb-0">Ascenceur</p>
                                            </dt>
                                            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                                                <p className="text-xl font-semibold text-gray-900 mb-0">Oui</p>
                                            </dd>
                                        </div>
                                        </>}
                                        {annonce.parking === "oui" && <>
                                            <div className="relative overflow-hidden">
                                            <dt>
                                                <div className="absolute rounded-md bg-indigo-50 p-3">
                                                    <ChevronRightIcon aria-hidden="true" className="size-6 text-indigo-800" />
                                                </div>
                                                <p className="ml-16 truncate text-sm font-medium text-gray-500 mb-0">Parking</p>
                                            </dt>
                                            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                                                <p className="text-xl font-semibold text-gray-900 mb-0">Oui</p>
                                            </dd>
                                        </div>
                                        </>}
                                        {annonce.cave === "oui" && <>
                                            <div className="relative overflow-hidden">
                                            <dt>
                                                <div className="absolute rounded-md bg-indigo-50 p-3">
                                                    <ChevronRightIcon aria-hidden="true" className="size-6 text-indigo-800" />
                                                </div>
                                                <p className="ml-16 truncate text-sm font-medium text-gray-500 mb-0">Cave</p>
                                            </dt>
                                            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                                                <p className="text-xl font-semibold text-gray-900 mb-0">Oui</p>
                                            </dd>
                                        </div>
                                        </>}
                                        {annonce.balcon === "oui" && <>
                                            <div className="relative overflow-hidden">
                                            <dt>
                                                <div className="absolute rounded-md bg-indigo-50 p-3">
                                                    <ChevronRightIcon aria-hidden="true" className="size-6 text-indigo-800" />
                                                </div>
                                                <p className="ml-16 truncate text-sm font-medium text-gray-500 mb-0">Balcon</p>
                                            </dt>
                                            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                                                <p className="text-xl font-semibold text-gray-900 mb-0">Oui</p>
                                            </dd>
                                        </div>
                                        </>}
                                        {annonce.terrasse === "oui" && <>
                                            <div className="relative overflow-hidden">
                                            <dt>
                                                <div className="absolute rounded-md bg-indigo-50 p-3">
                                                    <ChevronRightIcon aria-hidden="true" className="size-6 text-indigo-800" />
                                                </div>
                                                <p className="ml-16 truncate text-sm font-medium text-gray-500 mb-0">Terrasse</p>
                                            </dt>
                                            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                                                <p className="text-xl font-semibold text-gray-900 mb-0">Oui</p>
                                            </dd>
                                        </div>
                                        </>}
                                        {annonce.jardin === "oui" && <>
                                            <div className="relative overflow-hidden">
                                            <dt>
                                                <div className="absolute rounded-md bg-indigo-50 p-3">
                                                    <ChevronRightIcon aria-hidden="true" className="size-6 text-indigo-800" />
                                                </div>
                                                <p className="ml-16 truncate text-sm font-medium text-gray-500 mb-0">Jardin</p>
                                            </dt>
                                            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                                                <p className="text-xl font-semibold text-gray-900 mb-0">Oui</p>
                                            </dd>
                                        </div>
                                        </>}
                                        {annonce.douche === "oui" && <>
                                            <div className="relative overflow-hidden">
                                            <dt>
                                                <div className="absolute rounded-md bg-indigo-50 p-3">
                                                    <ChevronRightIcon aria-hidden="true" className="size-6 text-indigo-800" />
                                                </div>
                                                <p className="ml-16 truncate text-sm font-medium text-gray-500 mb-0">Douche</p>
                                            </dt>
                                            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                                                <p className="text-xl font-semibold text-gray-900 mb-0">Oui</p>
                                            </dd>
                                        </div>
                                        </>}
                                        {annonce.baignoire === "oui" && <>
                                            <div className="relative overflow-hidden">
                                            <dt>
                                                <div className="absolute rounded-md bg-indigo-50 p-3">
                                                    <ChevronRightIcon aria-hidden="true" className="size-6 text-indigo-800" />
                                                </div>
                                                <p className="ml-16 truncate text-sm font-medium text-gray-500 mb-0">Baignoire</p>
                                            </dt>
                                            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                                                <p className="text-xl font-semibold text-gray-900 mb-0">Oui</p>
                                            </dd>
                                        </div>
                                        </>}
                                        
                                        
                                    </dl>

                                </div>

                                {/** vendu loué énergétique */}
                                {annonce.vendu === "loue" && <>
                                    <div className="mt-8 mb-8 border-t-2 border-slate-50 pt-10">
                                        <p className="text-xl font-bold">Ce logement est vendu loué 🥳</p>
                                        <ul className="flex flex-column p-0 rounded-lg gap-1">
                                            <li className="bg-white flex flex-row justify-between items-center p-1 rounded-lg">
                                                <p className="m-0 text-base font-medium hover:underline hover:cursor-pointer" onClick={() => setOpenClasse(true)}>Loyer mensuel (charges comprises)</p>
                                                <div className="text-lg font-bold">
                                                    50€/mois
                                                </div>
                                            </li>
                                            <li className="bg-white flex flex-row justify-between items-center p-1 rounded-lg">
                                                <p className="m-0 text-base font-medium">Charges mensuelles</p>
                                                <div className="text-lg font-bold">
                                                    50€/mois
                                                </div>
                                            </li>
                                            <li className="bg-indigo-50 p-3 rounded-md flex flex-row justify-between items-center">
                                                <p className="m-0 text-base font-medium">Revenus annuels</p>
                                                <div className="text-lg font-bold ">
                                                    2300€/an
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                </>}


                                {/** performance énergétique */}
                                <div className="mt-8 mb-8 border-t-2 border-slate-50 pt-10">
                                    <p className="text-xl font-bold">Performances énergétiques</p>
                                    <ul className="flex flex-column p-0 rounded-lg gap-1">
                                        <li className="bg-white flex flex-row justify-between items-center p-2 rounded-lg">
                                            <p className="m-0 text-base font-medium hover:underline hover:cursor-pointer" onClick={() => setOpenClasse(true)}>Classe énergie <QuestionMarkCircleIcon className="text-slate-300 inline-flex w-6" /></p>
                                            <div className="text-lg bg-[#CDFD33] text-black px-3 py-2 text-center rounded-lg font-bold border-4 border-white">
                                                {annonce.DPE}
                                            </div>
                                        </li>
                                        <li className="bg-white flex flex-row justify-between items-center p-2 rounded-lg">
                                            <p className="m-0 text-base font-medium  hover:underline hover:cursor-pointer" onClick={() => setOpenGes(true)}>GES <QuestionMarkCircleIcon className="text-slate-300 inline-flex w-6" /></p>
                                            <div className="text-lg bg-[#D2ADF1] text-black px-3 py-2 text-center rounded-lg font-bold border-4 border-white">
                                                {annonce.GES}
                                            </div>
                                        </li>
                                    </ul>

                                </div>

                                {/** charges */}
                                <div className="mt-8 mb-8 border-t-2 border-slate-50 pt-10">
                                    <p className="text-xl font-bold">A propos des charges</p>
                                    <div className="grid grid-flow-col justify-stretch gap-2">
                                        <div className="bg-indigo-50 flex flex-wrap items-center justify-between gap-0 p-4 rounded-xl text-black">
                                            <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight mb-0">{annonce.fonciere} €<span className="text-sm ml-2">/an</span></dd>
                                            <dt className="text-sm/6 font-medium">Taxe foncière</dt>
                                        </div>
                                        <div className="bg-indigo-50 flex flex-wrap items-baseline justify-between gap-0 p-4 rounded-xl text-black">
                                            <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight">{annonce.charges_annuelles} €<span className="text-sm ml-2">/an</span></dd>
                                            <dt className="text-sm/6 font-medium">Charges copropriété</dt>
                                        </div>
                                    </div>
                                    <p className="text-sm text-slate-500 mt-10">Estimation de vos frais de notaire</p>

                                    <div className="overflow-hidden rounded-xl border border-gray-200">
                                        <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                                            <div className="bg-[#ECFCF7] flex w-20 shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                                                <DocumentCurrencyEuroIcon className="text-[#2AC1AF] size-10" />
                                            </div>
                                            <div className="text-3xl font-bold text-gray-900">{annonce.frais_notaire} €</div>
                                        </div>
                                        <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm/6">
                                            <div className="flex justify-between gap-x-4 py-3">
                                                <dt className="text-gray-700">Quand devrais-je payer ses frais ?</dt>
                                            </div>
                                            <div className="flex justify-between gap-x-4 py-3">
                                                <dt className="text-gray-700">Comment réduire mes frais de notaire ?</dt>
                                            </div>
                                        </dl>
                                        <Link to="/fiches-pratiques-notaire" target="_blank" className="btn-indigo no-underline inline-flex">Suivez nos conseils <ArrowUpRightIcon className="size-6" /></Link>
                                    </div>

                                </div>
                                <CardAcheteur />

                            </div>
                            {/** sidebar */}
                            <div className=" lg:col-span-4 md:col-span-5">
                                <div className="sticky top-20">
                                    {/** price  */}
                                    <div className="p-6 bg-[#F7F7F7F7] rounded-sm flex items-center gap-3">
                                        <div className="p-3 rounded-lg bg-indigo-100 text-indigo-600 text-center">
                                            <CurrencyEuroIcon className="w-6 h-6" />
                                        </div>
                                        <p className="text-3xl font-medium mb-0">
                                            <span className="text-sm block">Prix</span>
                                            {annonce.prix} €
                                        </p>
                                    </div>
                                    {/** contact  */}
                                    <div className=" mt-10 p-6 bg-[#F7F7F7F7] rounded-sm flex flex-col items-center gap-3">

                                        <h3 as="h3" className="text-base font-semibold text-gray-900">
                                            Contacter le propriétaire
                                        </h3>
                                        <form action="#" method="POST" className="w-full" onSubmit={handleContact}>
                                            {messageErrorRep === true && <>
                                            <AlertComponent type="error" message="La réponse de calcul n'est pas bonne"/>
                                            </>}
                                        {messageSucces === true ? (
                                            <>
                                                <div className="rounded-md bg-green-50 p-4">
                                            <div className="flex">
                                                <div className="shrink-0">
                                                <CheckCircleIcon aria-hidden="true" className="size-5 text-green-400" />
                                                </div>
                                                <div className="ml-3">
                                                <p className="text-sm font-medium text-green-800">Votre message a bien été envoyé</p>
                                                </div>
                                                <div className="ml-auto pl-3">
                                                <div className="-mx-1.5 -my-1.5">
                                                    <button
                                                    type="button"
                                                    className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                                    >
                                                    <span className="sr-only">Fermer</span>
                                                    <XMarkIcon aria-hidden="true" className="size-5" />
                                                    </button>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                            </>
                                        ) : (
                                            <>
                                                 <div>
                                                <div className="mt-2">
                                                    <input
                                                        placeholder="Votre nom*"
                                                        id="nom"
                                                        name="nom"
                                                        type="text"
                                                        required
                                                        className="input-clotere"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mt-2">
                                                    <input
                                                        placeholder="Votre email*"
                                                        name="email"
                                                        type="email"
                                                        required
                                                        className="input-clotere"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mt-2">
                                                    <input
                                                        placeholder="Votre telephone"
                                                        name="telephone"
                                                        type="phone"
                                                        className="input-clotere"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mt-2">
                                                    <input
                                                        placeholder="2+2"
                                                        name="calcul"
                                                        type="text"
                                                        className="input-clotere"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mt-2">
                                                    <textarea
                                                        placeholder="Votre message"
                                                        id="message"
                                                        name="message"
                                                        rows={4}
                                                        className="input-clotere"
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <button
                                                    type="submit"
                                                    className="btn-indigo mt-3"
                                                >
                                                    Envoyer
                                                </button>
                                            </div>
                                            </>
                                        )}

                                           
                                        </form>
                                    </div>

                                    {/** deroulé vente  */}
                                    <div className=" mt-10 p-6 bg-[#F7F7F7F7] rounded-sm flex flex-col items-center gap-3">
                                        <h3 as="h3" className="text-base font-semibold text-gray-900">
                                            Tout ce qu'il faut pour une bonne transaction immobilière
                                        </h3>
                                        <ul role="list" className="divide-y divide-gray-100 text-left p-0">
                                            <li className="flex gap-x-2 py-3">
                                                <CheckCircleIcon className="text-emerald-500 size-8" />
                                                <div className="min-w-0">
                                                    <p className="text-sm/5 font-semibold text-gray-900 m-0">Trouvez toutes les réponses à vos questions sur l'annonce</p>
                                                </div>
                                            </li>
                                            <li className="flex gap-x-2 py-3">
                                                <CheckCircleIcon className="text-emerald-500 size-8" />
                                                <div className="min-w-0">
                                                    <p className="text-sm/5 font-semibold text-gray-900 m-0">Contactez simplement le propriétaire</p>
                                                </div>
                                            </li>
                                            <li className="flex gap-x-2 py-3">
                                                <CheckCircleIcon className="text-emerald-500 size-8" />
                                                <div className="min-w-0">
                                                    <p className="text-sm/5 font-semibold text-gray-900 m-0">Faites une offre d'achat conforme et sécurisée en ligne</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/** dernières annonces */}
                    <div className="mt-28">
                        <Listing title="Autres annonces immobilières entre particuliers dans cette région" region="rhones" />
                    </div>


                    <FooterVente />


                </div>

                {/** modal diag dpe classe */}
                <Dialog open={openClasse} onClose={setOpenClasse} className="relative z-10">
                    <ContentModalDiagDpe />
                </Dialog>
                {/** modal diag erp */}
                <Dialog open={openGes} onClose={setOpenGes} className="relative z-10">
                    <ContentModalDiagGes />
                </Dialog>

            </>}
            {!annonce && <><Loading/></>}

        </>
    );
}

export default Annonce;