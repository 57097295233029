import { CheckCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'


function AlertComponent(args) {
    

    return (
      <>
          <div className={"rounded-md p-4 " + (args.type === "success" ? 'bg-green-50' : '') + (args.type === "error" ? 'bg-red-50' : '') + (args.type === "info" ? 'bg-blue-50' : '')}>
        <div className="flex">
          <div className="shrink-0">
            {args.type === "success" &&
            <CheckCircleIcon aria-hidden="true" className="size-5 text-green-400" />
            }
            {args.type === "error" &&
            <XCircleIcon aria-hidden="true" className="size-5 text-red-400" />
            }
            {args.type === "info" &&
            <InformationCircleIcon aria-hidden="true" className="size-5 text-blue-400" />
            }
            
          </div>
          <div className="ml-3">
            {args.type === "success" && <p className="text-sm font-medium text-green-800 mb-0">{args.message}</p>}
            {args.type === "error" && <p className="text-sm font-medium text-red-800 mb-0">{args.message}</p>}
            {args.type === "info" && <p className="text-sm font-medium text-blue-800 mb-0">{args.message}</p>}
            
          </div>
        </div>
      </div>
      </>
    );
}

export default AlertComponent;