import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Offcanvas,
  NavItem,
  OffcanvasHeader,
  OffcanvasBody,
  FormGroup,
  Form,
  Label,
  Input,
  Spinner,
  Button,
  Modal,
} from "reactstrap";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useMemberstack } from "@memberstack/react";
import { TitlePageApp, TitlePage,TitlePageBig } from "../style/Layout";
import IconPDF from "../img/icon-pdf.png";
import { FileUploader } from "react-drag-drop-files";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import styled from "styled-components";
import {DocumentIcon } from "@heroicons/react/24/solid";
import AlertComponent from "../components/Alert"
//pdf viewer
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css"; // Styles de base du PDF viewer
import "@react-pdf-viewer/default-layout/lib/styles/index.css"; // Styles de la mise en page par défaut

function Documents(args) {
  const [data, setData] = useState([]);
  const memberstack = useMemberstack();
  const [userInfo, setUserInfo] = useState(null);
  const [valueNomDoc, setValueNomDoc] = useState(null);

  const [idSelectedDoc, setIdSelectedDoc] = useState(null);
  const [listTabs, setListTabs] = useState([]);

  const [showSideBar, setShowSideBar] = useState(false);
  const toggleSideBar = () => {
    setShowSideBar(false);
    setDefaultValueType(null);
    setIdSelectedDoc(null);
    setFile(null);
    setValueNomDoc(null);
  };
  /**pdf viewer const */
  const [pdfName, setPdfName] = useState(null);
  const toggleModal = () => setPdfName(null);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [modalConfirm, setModalConfirm] = useState(false);

  const [docToDelete, setDocToDelete] = useState(null);

  const toggleModalConfirm = () => setModalConfirm(!modalConfirm);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [storage, setStorage] = useState([]);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [defaultValueType, setDefaultValueType] = useState(null);

  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
    console.log(file);
  };

  const fileTypes = ["PDF", "PNG", "JPG"];

  const firebaseConfig = {
    apiKey: "AIzaSyBx-T0tuEcSlSFsPF1zyeYQo3pZG-zytz8",
    authDomain: "clotere-33ee1.firebaseapp.com",
    projectId: "clotere-33ee1",
    storageBucket: "clotere-33ee1.appspot.com",
    messagingSenderId: "488961682641",
    appId: "1:488961682641:web:b89810aa37cda07d7169f0",
  };

  var appFirebase = null;

  useEffect(() => {
    const fetchData = async () => {
      // eslint-disable-next-line
      appFirebase = initializeApp(firebaseConfig);
      setStorage(getStorage(appFirebase));
    };

    fetchData();
  }, []);

  useEffect(() => {
    getUserDocuments();
  }, []);

  const getUserDocuments = async () => {
    var member = await memberstack.getCurrentMember();

    if (member !== null && member.data.metaData.airtable_id !== undefined) {
      const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/user/${member.data.metaData.airtable_id}`;

      var userData = await fetch(URL, {
        method: "GET",
        headers: {
          Authorization:
            "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
          "content-type": "application/x-www-form-urlencoded",
          Accept: "application/json, text/plain, /",
        },
      })
        .then((res) => res.json())
        .catch((error) => console.log(error));

      setUserInfo(userData.fields);
    }

    const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/document?filterByFormula=({id_from_transaction} = '${userData.fields.transaction_id}')`;

    var dataTemp = await fetch(URL, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
        Accept: "application/json",
      },
    }).then((res) => res.json());

    var dataFilterTemp = null;
    let categoriesFiltrees = null;

    if (userData.fields.role === "acheteur") {
      categoriesFiltrees = ["acheteur", "commun"];
    } else if (userData.fields.role === "vendeur") {
      categoriesFiltrees = ["vendeur", "commun"];
    }

    if (categoriesFiltrees != null) {
      dataFilterTemp = dataTemp.records.filter((objet) => {
        if (objet.fields.categorie != null) {
          return categoriesFiltrees.includes(objet.fields.categorie[0]);
        } else {
          return false;
        }
      });
    } else {
      dataFilterTemp = dataTemp.records;
    }

    if (dataFilterTemp != null && dataFilterTemp.length > 0) {
      setData(
        dataFilterTemp.map((item) => {
          const { fields } = item;
          if (fields.hasOwnProperty("document")) {
            fields.nomDocument = fields.document[0].filename;
            fields.typeDocument = fields.document[0].type;
            const documentUrl = fields.document[0].url;
            fields.document = documentUrl;
          }
          return fields;
        })
      );
    }
  };

  useEffect(() => {
    if (data !== undefined && data !== null) {
      
      var nomsDocuments = [];

      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        nomsDocuments.push(element.nom);
        // setDropdownCategorie(dropdownCategorie => [...dropdownCategorie, element.categorie[0]])
      }
    }
  }, [data]);

  const handleChangeSelect = (e) => {
    console.log(e);
    setDefaultValueType(e.target.value);
  };

  const handleChangeNom = (e) => {
    console.log(e);
    setValueNomDoc(e.target.value);
  };

  const handleDeleteDocument = async (doc) => {
    toggleModalConfirm();
    const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/document/${doc.id}`;

    if (doc.type === "autre") {
      await fetch(URL, {
        method: "DELETE",
        headers: {
          Authorization:
            "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
          Accept: "application/json",
          "content-type": "application/json",
        },
      }).then((res) => res.json());
    } else {
      await fetch(URL, {
        method: "PATCH",
        headers: {
          Authorization:
            "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          fields: {
            document: [],
            etat: "non ajouté",
            date_upload: "",
            qui_upload: "",
          },
        }),
      }).then((res) => res.json());
    }

    await getUserDocuments();

    setSuccess("Document supprimé avec succès !");

    setTimeout(() => {
      setSuccess(null);
    }, 5000);
  };

  const handleDelete = (event) => {
    // Empêcher la propagation de l'événement de clic
    event.stopPropagation();

    // Empêcher le comportement par défaut (ouvrir l'explorateur de fichiers)
    event.preventDefault(); // Empêche l'ouverture de l'explorateur de fichiers
    setFile(null);
  };

  const formattedDate = () => {
    var date = new Date();

    // Obtenir le jour, le mois, l'année, l'heure et les minutes
    var jour = date.getDate();
    var mois = date.getMonth() + 1; // Ajouter 1 car les mois sont indexés à partir de 0
    var annee = date.getFullYear();
    var heures = date.getHours();
    var minutes = date.getMinutes();

    // Formatage du jour et du mois pour qu'ils aient toujours 2 chiffres
    jour = (jour < 10 ? "0" : "") + jour;
    mois = (mois < 10 ? "0" : "") + mois;

    // Formatage de l'heure et des minutes pour qu'ils aient toujours 2 chiffres
    heures = (heures < 10 ? "0" : "") + heures;
    minutes = (minutes < 10 ? "0" : "") + minutes;

    // Création de la chaîne de caractères au format jj/mm/aaaa hh:mm
    return jour + "/" + mois + "/" + annee + " " + heures + ":" + minutes;
  };

  const handleUpload = async () => {
    try {
      setLoadingUpload(true);

      // ---------------------- //
      // Formattage de la date  //
      // ---------------------- //
      var date = formattedDate();

      // Upload du fichier sur firestore le temps de l'upload

      const storageRef = ref(storage, file.name);
      await uploadBytes(storageRef, file);

      const url = await getDownloadURL(storageRef);

      // Requete pour upload sur airtable
      if (defaultValueType !== "autre") {
        const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/document/${idSelectedDoc}`;

        await fetch(URL, {
          method: "PATCH",
          headers: {
            Authorization:
              "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
            Accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({
            fields: {
              document: [
                {
                  url: url,
                  filename: file.name,
                },
              ],
              etat: "ajouté",
              date_upload: date,
              qui_upload: userInfo.airtable_id,
            },
          }),
        }).then((res) => res.json());
      } else {
        const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/document`;

        await fetch(URL, {
          method: "POST",
          headers: {
            Authorization:
              "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
            Accept: "application/json",
            "content-type": "application/json",
          },
          body: JSON.stringify({
            fields: {
              document: [
                {
                  url: url,
                  filename: file.name,
                },
              ],
              etat: "ajouté",
              date_upload: date,
              nom: valueNomDoc,
              qui_upload: userInfo.airtable_id,
              type: "autre",
              categorie: [userInfo.role],
              transaction: [userInfo.transaction_id],
            },
          }),
        }).then((res) => res.json());
      }

      setSuccess("Document ajouté avec succès !");

      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    } catch (e) {
      console.log(e);
      setError("Erreur lors de l'upload de votre document.");

      setTimeout(() => {
        setError(null);
      }, 5000);
    }

    await getUserDocuments();
    setLoadingUpload(false);
    toggleSideBar();
  };

  const uploadWithoutPreSelection = async () => {
    try {
      setLoadingUpload(true);

      // Upload du fichier sur firestore le temps de l'upload

      const storageRef = ref(storage, file.name);
      await uploadBytes(storageRef, file);

      const url = await getDownloadURL(storageRef);

      var date = formattedDate();

      console.log("Date", date);
      const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/document`;

      await fetch(URL, {
        method: "POST",
        headers: {
          Authorization:
            "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          fields: {
            document: [
              {
                url: url,
                filename: file.name,
              },
            ],
            etat: "ajouté",
            date_upload: date,
            nom: valueNomDoc,
            qui_upload: userInfo.airtable_id,
            type: defaultValueType,
            categorie: [userInfo.role],
            transaction: [userInfo.transaction_id],
          },
        }),
      }).then((res) => res.json());

      setSuccess("Document ajouté avec succès !");

      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    } catch (e) {
      console.log(e);
      setError("Erreur lors de l'upload de votre document.");

      setTimeout(() => {
        setError(null);
      }, 5000);
    }

    await getUserDocuments();
    setLoadingUpload(false);
    toggleSideBar();
  };

  const stackSelected = (
    <DragAndDropContainer style={{ width: "100%!important" }}>
      <img
        alt="IconPDF"
        style={{ height: "60px", marginBottom: "10px" }}
        src={IconPDF}
      />
      <div className="subtitle">Fichiers acceptés : PDF/JPG/PNG</div>
      <div className="subtitle">Taille maximum : 40MB</div>
      <br />
      <div style={{ display: "inline-flex" }}>
        <div className="file-name">
          {file !== undefined && file !== null && file.name}
        </div>
        <div
          onClick={toggleModalConfirm}
          style={{ textDecoration: "underline" }}
        >
          Supprimer
        </div>
      </div>
    </DragAndDropContainer>
  );

  const stackNotSelected = (
    <DragAndDropContainer style={{ width: "100%!important" }}>
      <img
        style={{ height: "60px", marginBottom: "10px" }}
        alt="drop"
        src={IconPDF}
      />
      <p style={{ fontWeight: "500" }}>Déposez votre document ici ou</p>
      <p className="title-underline">Sélectionnez un document</p>
      <div className="subtitle">Fichiers acceptés : PDF/JPG/PNG</div>
      <div className="subtitle">Taille maximum : 40MB</div>
    </DragAndDropContainer>
  );

  const addDocument = (doc) => {
    console.log("click",doc)
    setIdSelectedDoc(doc.id);
    setDefaultValueType(doc.type);
    setValueNomDoc(doc.nom);
    setShowSideBar(true);
  };
  const deleteDocument = (doc) => {
    console.log("delete",doc)
    setDocToDelete(doc);
    toggleModalConfirm();
  };

  
  //console.log("args", args.user);
  //console.log("data", data)

  {/** doc acheteur */}
  if (args.user.role === "acheteur") {
    return (
      <>
      {/**modal vu document */}
      <Modal isOpen={pdfName != null} toggle={toggleModal} size="lg" centered>
        {pdfName != null ? (
          pdfName.type === "application/pdf" ? (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
              <Viewer
                fileUrl={pdfName.url}
                plugins={[
                  // Register plugins
                  defaultLayoutPluginInstance,
                ]}
              />
            </Worker>
          ) : (
            <img alt="document" src={pdfName.url}></img>
          )
        ) : (
          <></>
        )}
      </Modal>
      {/** dialog confirmation suppression doc */}
      <Dialog open={modalConfirm} onClose={setModalConfirm} toggle={toggleModalConfirm} {...args} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="sm:flex sm:items-center">
              <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
                <ExclamationTriangleIcon aria-hidden="true" className="size-6 text-red-600" />
              </div>
              <div className="mt-3 text-left sm:ml-4 sm:mt-0 sm:text-left">
                <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                  Voulez-vous vraiment supprimer ce document ?
                </DialogTitle>
                
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={() => handleDeleteDocument(docToDelete)}
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
              >
                Supprimer
              </button>
              <button
                type="button"
                data-autofocus
                onClick={() => setModalConfirm(false)}
                className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Annuler
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
      
      {/** ajout doc */}
      <Offcanvas
        isOpen={showSideBar}
        toggle={toggleSideBar}
        direction="end"
        scrollable
      >
        <OffcanvasHeader toggle={toggleSideBar}>
          Ajouter un document
        </OffcanvasHeader>
        <OffcanvasBody>
          {loadingUpload ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%!important",
              }}
            >
              <Spinner
                style={{ width: "5rem", height: "5rem" }}
                color="primary"
              />
            </div>
          ) : (
            <Form style={{ width: "100%!important" }}>
              <FormGroup>
                <FileUploader
                  style={{ width: "100%!important" }}
                  disabled={file != null}
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  children={file != null ? stackSelected : stackNotSelected}
                ></FileUploader>
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">De quel document s'agit-il ?</Label>

                <Input
                  disabled={idSelectedDoc !== null}
                  id="exampleSelect"
                  name="select"
                  type="select"
                  value={defaultValueType}
                  onChange={handleChangeSelect}
                >
                  {listTabs !== undefined && listTabs !== null ? (
                    listTabs
                      ?.filter((tab) => {
                        if (tab !== "tous les documents") {
                          return true;
                        } else {
                          return false; // ou simplement, return tab !== "tous les documents";
                        }
                      })
                      .map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))
                  ) : (
                    <></>
                  )}
                </Input>
              </FormGroup>
              <FormGroup>
                <Input
                  onChange={handleChangeNom}
                  disabled={idSelectedDoc !== null}
                  name="nom"
                  type="nom"
                  value={valueNomDoc}
                />
              </FormGroup>
              <FormGroup>
                <Button
                  onClick={
                    idSelectedDoc !== null
                      ? handleUpload
                      : uploadWithoutPreSelection
                  }
                  disabled={
                    file === null || valueNomDoc === null || valueNomDoc === ""
                  }
                  style={{ backgroundColor: "#1c6856" }}
                >
                  Ajouter
                </Button>
              </FormGroup>
            </Form>
          )}
        </OffcanvasBody>
      </Offcanvas>

      {/** ajout doc */}
        <Container>
          <Row className="d-flex align-self-start">
         
              <Col md="7">
                <TitlePageBig>Vos documents</TitlePageBig>
              </Col>
            {/**Si erreur upload */}
          {error == null ? <></> : <AlertComponent type="error" message={error}/>}
          {/**Si succès upload */}
          {success == null ? <></> : <AlertComponent type="success" message={success}/>}

            {/** doc identité */}
            <Col md="12" className="mt-3">
              <span className="inline-flex items-center rounded-md bg-purple-100 px-2 py-1 text-base font-bold text-purple-700 mb-2">
                Identité
              </span>
              <div className="overflow-hidden rounded-lg bg-white shadow p-3">
                <ul role="list" className="divide-y divide-gray-100 p-0 m-0">
                  {data.map((item, index) =>
                    <>
                    
                      {item.type === "personnel" &&
                        <li className="flex items-center justify-between gap-x-6 py-3">
                          <div className="min-w-0">
                            <div className="flex flex-column justify-start gap-x-3">
                              <div>
                                <DocumentIcon className="size-6 text-slate-200 inline-flex" />
                                <p className="text-sm/6 font-semibold text-gray-900 m-0">{item.nom}</p>
                              </div>
                              <p className="block text-sm/8 font-regular text-gray-600 m-0">{item.description}</p>
                            </div>
                          </div>
                          <div className="flex flex-none items-center gap-x-4">
                            {item.etat === "ajouté" &&
                              <>
                            
                                <button
                                onClick={() => {
                                  setPdfName({ url: item.document, type: item.typeDocument });
                                }}
                                  type="button"
                                  className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                >
                                  Voir
                                </button>
                                <button
                                onClick={() => {
                                  deleteDocument(item);
                                }}
                                  type="button"
                                  className="rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                                >
                                  Supprimer
                                </button>
                              </>
                            }
                            {item.etat === "non ajouté" &&
                              <>
                                <button
                                onClick={() => {
                                  addDocument(item);
                                }}
                                  type="button"
                                  className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                >
                                  + Ajouter
                                </button>
                              </>
                            }

                          </div>
                        </li>
                      }
                    </>
                  )}
                </ul>
              </div>
            </Col>

            {/** doc financement */}
            <Col md="12" className="mt-3">
              <span className="inline-flex items-center rounded-md bg-emerald-100 px-2 py-1 text-base font-bold text-emerald-700 mb-2">
                Vente et financement
              </span>
              <div className="overflow-hidden rounded-lg bg-white shadow p-3">
                <ul role="list" className="divide-y divide-gray-100 p-0 m-0">
                  {data.map((item, index) =>
                    <>
                    
                      {item.type === "vente" &&
                        <li className="flex items-center justify-between gap-x-6 py-3">
                          <div className="min-w-0">
                            <div className="flex flex-column justify-start gap-x-3">
                              <div>
                                <DocumentIcon className="size-6 text-slate-200 inline-flex" />
                                <p className="text-sm/6 font-semibold text-gray-900 m-0">{item.nom}</p>
                              </div>
                              <p className="block text-sm/8 font-regular text-gray-600 m-0">{item.description}</p>
                            </div>
                          </div>
                          <div className="flex flex-none items-center gap-x-4">
                            {item.etat === "ajouté" &&
                              <>
                                <button
                                  type="button"
                                  className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                >
                                  Voir
                                </button>
                                <button
                                  type="button"
                                  className="rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                                >
                                  Supprimer
                                </button>
                              </>
                            }
                            {item.etat === "non ajouté" &&
                              <>
                                <button
                                  type="button"
                                  className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                >
                                  + Ajouter
                                </button>
                              </>
                            }

                          </div>
                        </li>
                      }
                    </>
                  )}
                </ul>
              </div>
            </Col>

          </Row>
        </Container>
      </>
    )
  }

  {/** doc vendeur */}
  if (args.user.role === "vendeur") {
    return (
      <>
      {/**modal vu document */}
      <Modal isOpen={pdfName != null} toggle={toggleModal} size="lg" centered>
        {pdfName != null ? (
          pdfName.type === "application/pdf" ? (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
              <Viewer
                fileUrl={pdfName.url}
                plugins={[
                  // Register plugins
                  defaultLayoutPluginInstance,
                ]}
              />
            </Worker>
          ) : (
            <img alt="document" src={pdfName.url}></img>
          )
        ) : (
          <></>
        )}
      </Modal>
      {/** dialog confirmation suppression doc */}
      <Dialog open={modalConfirm} onClose={setModalConfirm} toggle={toggleModalConfirm} {...args} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="sm:flex sm:items-center">
              <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
                <ExclamationTriangleIcon aria-hidden="true" className="size-6 text-red-600" />
              </div>
              <div className="mt-3 text-left sm:ml-4 sm:mt-0 sm:text-left">
                <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                  Voulez-vous vraiment supprimer ce document ?
                </DialogTitle>
                
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={() => handleDeleteDocument(docToDelete)}
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
              >
                Supprimer
              </button>
              <button
                type="button"
                data-autofocus
                onClick={() => setModalConfirm(false)}
                className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Annuler
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
      
      {/** ajout doc */}
      <Offcanvas
        isOpen={showSideBar}
        toggle={toggleSideBar}
        direction="end"
        scrollable
      >
        <OffcanvasHeader toggle={toggleSideBar}>
          Ajouter un document
        </OffcanvasHeader>
        <OffcanvasBody>
          {loadingUpload ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%!important",
              }}
            >
              <Spinner
                style={{ width: "5rem", height: "5rem" }}
                color="primary"
              />
            </div>
          ) : (
            <Form style={{ width: "100%!important" }}>
              <FormGroup>
                <FileUploader
                  style={{ width: "100%!important" }}
                  disabled={file != null}
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  children={file != null ? stackSelected : stackNotSelected}
                ></FileUploader>
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">De quel document s'agit-il ?</Label>

                <Input
                  disabled={idSelectedDoc !== null}
                  id="exampleSelect"
                  name="select"
                  type="select"
                  value={defaultValueType}
                  onChange={handleChangeSelect}
                >
                  {listTabs !== undefined && listTabs !== null ? (
                    listTabs
                      ?.filter((tab) => {
                        if (tab !== "tous les documents") {
                          return true;
                        } else {
                          return false; // ou simplement, return tab !== "tous les documents";
                        }
                      })
                      .map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))
                  ) : (
                    <></>
                  )}
                </Input>
              </FormGroup>
              <FormGroup>
                <Input
                  onChange={handleChangeNom}
                  disabled={idSelectedDoc !== null}
                  name="nom"
                  type="nom"
                  value={valueNomDoc}
                />
              </FormGroup>
              <FormGroup>
                <Button
                  onClick={
                    idSelectedDoc !== null
                      ? handleUpload
                      : uploadWithoutPreSelection
                  }
                  disabled={
                    file === null || valueNomDoc === null || valueNomDoc === ""
                  }
                  style={{ backgroundColor: "#1c6856" }}
                >
                  Ajouter
                </Button>
              </FormGroup>
            </Form>
          )}
        </OffcanvasBody>
      </Offcanvas>

      {/** vu listz doc */}
        <Container>
          <Row className="d-flex align-self-start">
            <TitlePageApp>
              <Col md="7">
                <TitlePageBig>Vos documents</TitlePageBig>
              </Col>
            </TitlePageApp>
            {/**Si erreur upload */}
          {error == null ? <></> : <AlertComponent type="error" message={error}/>}
          {/**Si succès upload */}
          {success == null ? <></> : <AlertComponent type="success" message={success}/>}

            {/** doc identité */}
            <Col md="12" className="mt-3">
              <span className="inline-flex items-center rounded-md bg-purple-100 px-2 py-1 text-base font-bold text-purple-700 mb-2">
                Identité
              </span>
              <div className="overflow-hidden rounded-lg bg-white shadow p-3">
                <ul role="list" className="divide-y divide-gray-100 p-0 m-0">
                  {data.map((item, index) =>
                    <>
                    
                      {item.type === "personnel" &&
                        <li className="flex items-center justify-between gap-x-6 py-3">
                          <div className="min-w-0">
                            <div className="flex flex-column justify-start gap-x-3">
                              <div>
                                <DocumentIcon className="size-6 text-slate-200 inline-flex" />
                                <p className="text-sm/6 font-semibold text-gray-900 m-0">{item.nom}</p>
                              </div>
                              <p className="block text-sm/8 font-regular text-gray-600 m-0">{item.description}</p>
                            </div>
                          </div>
                          <div className="flex flex-none items-center gap-x-4">
                            {item.etat === "ajouté" &&
                              <>
                            
                                <button
                                onClick={() => {
                                  setPdfName({ url: item.document, type: item.typeDocument });
                                }}
                                  type="button"
                                  className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                >
                                  Voir
                                </button>
                                <button
                                onClick={() => {
                                  deleteDocument(item);
                                }}
                                  type="button"
                                  className="rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                                >
                                  Supprimer
                                </button>
                              </>
                            }
                            {item.etat === "non ajouté" &&
                              <>
                                <button
                                onClick={() => {
                                  addDocument(item);
                                }}
                                  type="button"
                                  className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                >
                                  + Ajouter
                                </button>
                              </>
                            }

                          </div>
                        </li>
                      }
                    </>
                  )}
                </ul>
              </div>
            </Col>

            {/** doc financement */}
            <Col md="12" className="mt-3">
              <span className="inline-flex items-center rounded-md bg-emerald-100 px-2 py-1 text-base font-bold text-emerald-700 mb-2">
                Vente
              </span>
              <div className="overflow-hidden rounded-lg bg-white shadow p-3">
                <ul role="list" className="divide-y divide-gray-100 p-0 m-0">
                  {data.map((item, index) =>
                    <>
                    
                      {item.type === "vente" &&
                        <li className="flex items-center justify-between gap-x-6 py-3">
                          <div className="min-w-0">
                            <div className="flex flex-column justify-start gap-x-3">
                              <div>
                                <DocumentIcon className="size-6 text-slate-200 inline-flex" />
                                <p className="text-sm/6 font-semibold text-gray-900 m-0">{item.nom}</p>
                              </div>
                              <p className="block text-sm/8 font-regular text-gray-600 m-0">{item.description}</p>
                            </div>
                          </div>
                          <div className="flex flex-none items-center gap-x-4">
                            {item.etat === "ajouté" &&
                              <>
                            
                                <button
                                onClick={() => {
                                  setPdfName({ url: item.document, type: item.typeDocument });
                                }}
                                  type="button"
                                  className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                >
                                  Voir
                                </button>
                                <button
                                onClick={() => {
                                  deleteDocument(item);
                                }}
                                  type="button"
                                  className="rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                                >
                                  Supprimer
                                </button>
                              </>
                            }
                            {item.etat === "non ajouté" &&
                              <>
                                <button
                                onClick={() => {
                                  addDocument(item);
                                }}
                                  type="button"
                                  className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                >
                                  + Ajouter
                                </button>
                              </>
                            }

                          </div>
                        </li>
                      }
                    </>
                  )}
                </ul>
              </div>
            </Col>

            {/** doc copropriete */}
            <Col md="12" className="mt-3">
              <span className="inline-flex items-center rounded-md bg-indigo-100 px-2 py-1 text-base font-bold text-indigo-700 mb-2">
                Copropriété
              </span>
              <div className="overflow-hidden rounded-lg bg-white shadow p-3">
                <ul role="list" className="divide-y divide-gray-100 p-0 m-0">
                  {data.map((item, index) =>
                    <>
                    
                      {item.type === "copropriété" &&
                        <li className="flex items-center justify-between gap-x-6 py-3">
                          <div className="min-w-0">
                            <div className="flex flex-column justify-start gap-x-3">
                              <div>
                                <DocumentIcon className="size-6 text-slate-200 inline-flex" />
                                <p className="text-sm/6 font-semibold text-gray-900 m-0">{item.nom}</p>
                              </div>
                              <p className="block text-sm/8 font-regular text-gray-600 m-0">{item.description}</p>
                            </div>
                          </div>
                          <div className="flex flex-none items-center gap-x-4">
                            {item.etat === "ajouté" &&
                              <>
                            
                                <button
                                onClick={() => {
                                  setPdfName({ url: item.document, type: item.typeDocument });
                                }}
                                  type="button"
                                  className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                >
                                  Voir
                                </button>
                                <button
                                onClick={() => {
                                  deleteDocument(item);
                                }}
                                  type="button"
                                  className="rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                                >
                                  Supprimer
                                </button>
                              </>
                            }
                            {item.etat === "non ajouté" &&
                              <>
                                <button
                                onClick={() => {
                                  addDocument(item);
                                }}
                                  type="button"
                                  className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                >
                                  + Ajouter
                                </button>
                              </>
                            }

                          </div>
                        </li>
                      }
                    </>
                  )}
                </ul>
              </div>
            </Col>

            {/** doc copropriete */}
            <Col md="12" className="mt-3">
              <span className="inline-flex items-center rounded-md bg-orange-100 px-2 py-1 text-base font-bold text-orange-700 mb-2">
                Technique et diagnostics
              </span>
              <div className="overflow-hidden rounded-lg bg-white shadow p-3">
                <ul role="list" className="divide-y divide-gray-100 p-0 m-0">
                  {data.map((item, index) =>
                    <>
                    
                      {item.type === "technique et diagnostics" &&
                        <li className="flex items-center justify-between gap-x-6 py-3">
                          <div className="min-w-0">
                            <div className="flex flex-column justify-start gap-x-3">
                              <div>
                                <DocumentIcon className="size-6 text-slate-200 inline-flex" />
                                <p className="text-sm/6 font-semibold text-gray-900 m-0">{item.nom}</p>
                              </div>
                              <p className="block text-sm/8 font-regular text-gray-600 m-0">{item.description}</p>
                            </div>
                          </div>
                          <div className="flex flex-none items-center gap-x-4">
                            {item.etat === "ajouté" &&
                              <>
                            
                                <button
                                onClick={() => {
                                  setPdfName({ url: item.document, type: item.typeDocument });
                                }}
                                  type="button"
                                  className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                >
                                  Voir
                                </button>
                                <button
                                onClick={() => {
                                  deleteDocument(item);
                                }}
                                  type="button"
                                  className="rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                                >
                                  Supprimer
                                </button>
                              </>
                            }
                            {item.etat === "non ajouté" &&
                              <>
                                <button
                                onClick={() => {
                                  addDocument(item);
                                }}
                                  type="button"
                                  className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                >
                                  + Ajouter
                                </button>
                              </>
                            }

                          </div>
                        </li>
                      }
                    </>
                  )}
                </ul>
              </div>
            </Col>

            {/** doc travaux */}
            <Col md="12" className="mt-3">
              <span className="inline-flex items-center rounded-md bg-slate-100 px-2 py-1 text-base font-bold text-slate-700 mb-2">
                Travaux
              </span>
              <div className="overflow-hidden rounded-lg bg-white shadow p-3">
                <ul role="list" className="divide-y divide-gray-100 p-0 m-0">
                  {data.map((item, index) =>
                    <>
                    
                      {item.type === "travaux" &&
                        <li className="flex items-center justify-between gap-x-6 py-3">
                          <div className="min-w-0">
                            <div className="flex flex-column justify-start gap-x-3">
                              <div>
                                <DocumentIcon className="size-6 text-slate-200 inline-flex" />
                                <p className="text-sm/6 font-semibold text-gray-900 m-0">{item.nom}</p>
                              </div>
                              <p className="block text-sm/8 font-regular text-gray-600 m-0">{item.description}</p>
                            </div>
                          </div>
                          <div className="flex flex-none items-center gap-x-4">
                            {item.etat === "ajouté" &&
                              <>
                            
                                <button
                                onClick={() => {
                                  setPdfName({ url: item.document, type: item.typeDocument });
                                }}
                                  type="button"
                                  className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                >
                                  Voir
                                </button>
                                <button
                                onClick={() => {
                                  deleteDocument(item);
                                }}
                                  type="button"
                                  className="rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                                >
                                  Supprimer
                                </button>
                              </>
                            }
                            {item.etat === "non ajouté" &&
                              <>
                                <button
                                onClick={() => {
                                  addDocument(item);
                                }}
                                  type="button"
                                  className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                >
                                  + Ajouter
                                </button>
                              </>
                            }

                          </div>
                        </li>
                      }
                    </>
                  )}
                </ul>
              </div>
            </Col>

            {/** doc autre */}
            <Col md="12" className="mt-3">
              <span className="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-base font-bold text-yellow-700 mb-2">
                Autre
              </span>
              <div className="overflow-hidden rounded-lg bg-white shadow p-3">
                <ul role="list" className="divide-y divide-gray-100 p-0 m-0">
                  {data.map((item, index) =>
                    <>
                    
                      {item.type === "autre" &&
                        <li className="flex items-center justify-between gap-x-6 py-3">
                          <div className="min-w-0">
                            <div className="flex flex-column justify-start gap-x-3">
                              <div>
                                <DocumentIcon className="size-6 text-slate-200 inline-flex" />
                                <p className="text-sm/6 font-semibold text-gray-900 m-0">{item.nom}</p>
                              </div>
                              <p className="block text-sm/8 font-regular text-gray-600 m-0">{item.description}</p>
                            </div>
                          </div>
                          <div className="flex flex-none items-center gap-x-4">
                            {item.etat === "ajouté" &&
                              <>
                            
                                <button
                                onClick={() => {
                                  setPdfName({ url: item.document, type: item.typeDocument });
                                }}
                                  type="button"
                                  className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                >
                                  Voir
                                </button>
                                <button
                                onClick={() => {
                                  deleteDocument(item);
                                }}
                                  type="button"
                                  className="rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                                >
                                  Supprimer
                                </button>
                              </>
                            }
                            {item.etat === "non ajouté" &&
                              <>
                                <button
                                onClick={() => {
                                  addDocument(item);
                                }}
                                  type="button"
                                  className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                >
                                  + Ajouter
                                </button>
                              </>
                            }

                          </div>
                        </li>
                      }
                    </>
                  )}
                </ul>
              </div>
            </Col>

          </Row>
        </Container>
      </>
    )
  }


}

export default Documents;


const DragAndDropContainer = styled.div`
  display: flex;
  justify-content: center; /* Centre les éléments horizontalement */
  align-items: center; /* Centre les éléments verticalement */
  border-style: dotted;
  border-color: #c3dbf7;
  border-width: 2px;
  border-radius: 5px;
  width: 100% !important;
  height: 250px;
  background-color: #eef6ff;
  flex-direction: column;

  .title-underline {
    color: #54958c;
    font-weight: 500;
    text-decoration: underline;
  }

  .subtitle {
    font-size: 15px;
  }

  .file-name {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: "40px";
  }
`;
