import React, { useState, useEffect } from "react";
import { useMemberstack } from "@memberstack/react";
import { Link } from 'react-router-dom';
import axios from "axios";
import { ButtonPrimarySmall } from "../style/Button";
import CardNotaire from "../annuaire/CardNotaire";
import Loading from '../components/Loading';



function Notaires(args, props) {
    const memberstack = useMemberstack();
    const [member, setMember] = useState(null);
    const [user, setUser] = useState(null);
    const [transaction, setTransaction] = useState(null);
    const [recommandations, setRecommandations] = useState();
    const [isLoading, setLoading] = useState(true);
    const [notairesVille, setNotairesVille] = useState(null);
    const [data, setData] = useState([]);



    //get info memberstack
    useEffect(() => {
        const fetchDataMemberstack = () => {
            setLoading(true);
            memberstack.getCurrentMember()
                .then(({ data: member }) => setMember(member))
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        };

        fetchDataMemberstack();
    }, []);

    //get info user airtable
    useEffect(() => {
        //avec les info memberstack on recupere les info user airtable
        //console.log("seulement si member est update");
        if (member !== null && member.metaData.airtable_id !== undefined) {
            const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/user/${member.metaData.airtable_id}`;

            fetch(
                URL,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                        'content-type': 'application/x-www-form-urlencoded',
                        "Accept": "application/json, text/plain, /"
                    },
                })
                .then((res) => res.json())
                .then((res) => {
                    //console.log("this user info", res);
                    setUser(res.fields);
                })
                .catch((error) => console.log(error));
        }
        // eslint-disable-next-line
    }, [member]);

    //get info transaction
    useEffect(() => {
        const fetchDataTransaction = async () => {
            //La transaction
            const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/transaction/${user.transaction_id}`;
            await fetch(
                URL,
                {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                        'content-type': 'application/x-www-form-urlencoded',
                        "Accept": "application/json, text/plain, /"
                    },
                })
                .then((res) => res.json())
                .then((res) => {
                    setTransaction(res.fields);
                    console.log("transac", res.fields)
                })
                .catch((error) => console.log("transaction info error", error),);

        };

        if (user !== null && member.metaData.airtable_id !== undefined) {
            fetchDataTransaction();
        }
        // eslint-disable-next-line
    }, [user]);

    //on recupère les notaires depuis le CSV et les reco notaire depuis airtable
    useEffect(() => {
        const SPREADSHEET_ID = process.env.REACT_APP_GOOGLE_SHEETS_ID;
        const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
        const RANGE = "France";
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${RANGE}?key=${API_KEY}`
                );
                const rows = response.data.values;
                //console.log("rows",rows);
                setData(rows);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        const fetchRecoNotaire = async () => {
            //on recupere les notaires recommander si il y en a
            //on check si 1 ou plusieurs reco.
            if (transaction.recommandations_notaire.length === 1) {
                //console.log("pas table");
                const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/recommandations_notaire/${transaction.recommandations_notaire}`;
                await fetch(
                    URL, { method: "GET", headers: { Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02", 'content-type': 'application/x-www-form-urlencoded', "Accept": "application/json, text/plain, /" }, })
                    .then((res) => res.json())
                    .then((res) => { setRecommandations(res.fields); })
                    .catch((error) => console.log("transaction info error", error),);
            } else {
                //console.log("table");

                const url = `https://api.airtable.com/v0/appD48APNaGA4GN0B/recommandations_notaire?filterByFormula=SEARCH("${transaction.id}",{transaction})`;

                await fetch(
                    url, { method: "GET", headers: { Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02", 'content-type': 'application/x-www-form-urlencoded', "Accept": "application/json, text/plain, /" }, })
                    .then((res) => res.json())
                    .then((res) => { setRecommandations(res.records); })
                    .catch((error) => console.log("transaction info error", error),);
            }
        };

        fetchData();
        if (transaction !== null) {
            fetchRecoNotaire();
        }

    }, [transaction]);

    //on filtre les notaires de la ville et du département de la transaction
    useEffect(() => {
        if (transaction !== null & recommandations !== null) {
            const ville = transaction.code_postal_from_bien[0];
            //console.log("ici", dep)

            if (data.length > 1) {
                const headers = data[0];
                const rows = data.slice(1);
                const formatted = rows.map((row) => {
                    const obj = {};
                    headers.forEach((header, index) => {
                        obj[header] = row[index] || "";
                    });
                    return obj;
                });

                if (formatted != null) {


                    var filteredDataVille = formatted.filter(
                        (item) => item.cp === ville
                    );

                    filteredDataVille.sort((a, b) => {
                        if (a.utilise_clotere === "oui" && b.utilise_clotere === "non") {
                            return -1;
                        }
                        if (a.utilise_clotere === "non" && b.utilise_clotere === "oui") {
                            return 1;
                        }
                        return 0;
                    });
                    //console.log("filtered",filteredData)
                    setNotairesVille(filteredDataVille);
                    setLoading(false);
                }
            }
        }
    }, [data]);


    if (isLoading) {
        return <Loading />;
    }
    return (
        <>
            {recommandations &&

                <div className="bg-gradient-to-b from-[#eaf3f2] to-[#1cf36c12] py-8">
                    <div className="container">
                        <Link to="/app/notaire" className="text-sm font-semibold text-gray-900 inline-flex no-underline items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-4">
                                <path fill-rule="evenodd" d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z" clip-rule="evenodd" />
                            </svg>
                            Retour
                        </Link>

                        <div className="flex flex-column justify-center mt-6 text-center">
                            <svg
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                                className="mx-auto size-12 text-gray-400"
                            >
                                <path
                                    d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <div>
                            <p className="mt-2 text-lg font-bold text-gray-900">Voici le(s) notaire(s) que nous vous recommandons </p>
                            <p className="text-lg text-gray-600">Nos recommandations sont basées sur la complexité de votre affaire, la spécialisation du notaire et sur vos besoins.</p>
                            </div>
                        </div>

                        {/** affichage des reco */}
                        <div className="flex flex-column space-y-6 justify-center items-center">
                                        {transaction.recommandations_notaire.length > 1 ?
                                            <>
                                                {recommandations.map((col, i) => (
                                                    <>
                                                        <CardNotaire className="reco"
                                                            nom={recommandations[i].fields.prenom + " " + recommandations[i].fields.nom}
                                                            adresse={recommandations[i].fields.adresse}
                                                            cp={recommandations[i].fields.cp}
                                                            ville={recommandations[i].fields.ville}
                                                            site={recommandations[i].fields.site}
                                                            telephone={recommandations[i].fields.telephone}
                                                            type_reco={recommandations[i].fields.type_reco}
                                                            reco="oui"
                                                            use="oui"
                                                            photo={recommandations[i].fields.photo_profil ? recommandations[i].fields.photo_profil[0].url : 'https://firebasestorage.googleapis.com/v0/b/clotere-33ee1.appspot.com/o/icn-notaire.svg?alt=media&token=598450ff-1cca-4afe-b05d-c8d7ecc8f1cd'}
                                                            lien_fiche={recommandations[i].fields.lien_fiche ? recommandations[i].fields.lien_fiche[0] : 'non'}
                                                            idTransaction={transaction.id}
                                                            idReco={recommandations[i].id}
                                                            idNotaire={recommandations[i].fields.notaire}
                                                        />
                                                    </>
                                                ))}
                                            </> :
                                            <>
                                                <CardNotaire className="reco"
                                                    nom={recommandations.prenom + " " + recommandations.nom}
                                                    adresse={recommandations.adresse}
                                                    cp={recommandations.cp}
                                                    ville={recommandations.ville}
                                                    site={recommandations.site}
                                                    telephone={recommandations.telephone}
                                                    reco="oui"
                                                    use="oui"
                                                    photo={recommandations.photo_profil ? recommandations.photo_profil[0].url : 'https://firebasestorage.googleapis.com/v0/b/clotere-33ee1.appspot.com/o/icn-notaire.svg?alt=media&token=598450ff-1cca-4afe-b05d-c8d7ecc8f1cd'}
                                                    type_reco={recommandations.type_reco}
                                                    lien_fiche={recommandations.lien_fiche ? recommandations.lien_fiche[0] : 'non'}
                                                    idTransaction={transaction.id}
                                                    idReco={recommandations.id}
                                                    idNotaire={recommandations.notaire}
                                                />
                                            </>
                                        }
                        </div>

                        {/** autres reco géo */}
                        <div className="flex flex-column justify-center items-center mt-6 text-center mb-16">
                            <p className="mt-2 text-base font-medium text-gray-900">Vous ne trouvez pas votre bonheur ?<br/>  {notairesVille.length} notaires existent à {transaction.ville_from_bien} <span role="img">👇</span> </p>
                            <ButtonPrimarySmall color="primary" target="blank" href="https://clotere.fr/trouver-votre-notaire">Trouvez d'autres notaires</ButtonPrimarySmall>
                        </div>
                    </div>

                </div>
            }
        </>

    );

}

export default Notaires;