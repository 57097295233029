import React from "react";
/** composants **/
import { Navbar } from '../Navbar';
import Footer from '../components/Footer';
import BlocCta from '../components/BlocCta';
import { Container } from "../annuaire/Container";
import Faq from '../components/Faq';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
//img & icon
import { CheckIcon } from "@heroicons/react/20/solid";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import HommeSouriant from "../img/homme_souriant.webp";
import AchatAvantage2 from "../img/trouver-notaire-acheteur.webp";

const posts = [
    {
        id: 1,
        title: 'Quel est le rôle du notaire dans un achat immobilier ?',
        href: 'https://clotere.framer.website/blog/le-r%C3%B4le-du-notaire-dans-la-vente-d-un-bien-immobilier',
        description: "Si vous avez un projet d’achat immobilier, vous devez savoir que le notaire tient un rôle indispensable dans le processus d’achat, que ce soit pour établir des documents administratifs et juridiques, des signatures, mais aussi pour une mission de conseil. Voici ses différentes attributions.",
        imageUrl:
            'https://framerusercontent.com/images/iSpNwoZYeVubhimwPBiD3iJpg8.webp',
        category: { title: 'Rôle du notaire', href: '#' },
    },
    {
        id: 2,
        title: 'Quels sont les documents nécessaires pour vendre ?',
        href: 'https://clotere.framer.website/blog/quels-sont-les-documents-n%C3%A9cessaires-pour-vendre-un-bien-immobilier',
        description: "La vente d'un bien immobilier implique la préparation de divers documents essentiels pour assurer la transparence et la conformité légale de la transaction. Voici une liste des principaux documents à fournir par le vendeur principalement, lors de la vente de votre propriété.",
        imageUrl:
            'https://framerusercontent.com/images/7e3tCn5m9oQMkT40jRyfEeoWw.png',
        category: { title: 'Documents de vente', href: '#' },
    },
    {
        id: 3,
        title: 'Un ou deux notaires pour votre achat immobilier ?',
        href: 'https://clotere.framer.website/blog/un-ou-deux-notaires-pour-votre-achat-immobilier',
        description: "Bien que le notaire du vendeur soit souvent l’unique point de contact, l’acheteur a la possibilité de désigner son propre notaire pour sécuriser davantage la transaction. Dans ce cas, les deux professionnels collaborent en partageant les responsabilités.",
        imageUrl:
            'https://framerusercontent.com/images/O8SQKbN44JjN2YEQyv7JMUsuxI.webp',
        category: { title: 'Notaire acheteur', href: '#' },
    },
]

function Acheter(args) {
    return (
        <>
            <Helmet>
                <title>Trouver un notaire pour votre achat immobilier</title>
                <meta name="robots" content="index,follow" />
                <meta name="googlebot" content="index,follow" />
                <meta name='description' content='Trouvez votre notaire pour vous accompagner dans votre achat immobilier grâce à Clotere, la solution qui simplifie votre passage chez le notaire.' />
                <meta property="og:url" content="https://clotere.fr/notaire-acheteur" />
                <meta property="og:site_name" content="Clotere" />
                <meta property="og:image" content="https://clotere.fr/facebook-share-webp" />
                <meta property="og:type" content="website" />
            </Helmet>

            <Navbar user={args.user} />

            <Container>
                {/** header */}
                <header class="flex flex-row flex-wrap justify-center bg-gradient-to-r from-teal-200 to-teal-500 p-10 rounded-lg">
                    <div className="w-full sm:basis-1/2 p-4">
                        <h1 className="font-normal text-transparent text-[50px] tracking-[0] leading-10 mb-8" style={{ lineHeight: "50px" }}>
                            <span className="font-medium text-black">Trouvez un notaire <span className="font-bold mt-2 text-indigo-800">en tant qu'acheteur</span>. </span>
                        </h1>
                        <p className="text-[25px]">
                            Trouvez votre notaire pour défendre vos intêrets lors de votre achat.
                        </p>
                        <div className="flex flex-column justify-start gap-3">
                            <div className="flex items-center gap-2 relative flex-[0_0_auto]">
                                <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                    <p className="m-0 text-lg"><b className="text-indigo-800">Répondez à quelques questions</b> via un questionnaire</p>
                                </div>
                            </div>
                            <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                    <p className="m-0 text-lg"><b className="text-indigo-800">Complétez votre dossier</b> avec les documents nécessaires dans votre espace sécurisé</p>
                                </div>
                            </div>
                            <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                    <p className="m-0 text-lg"><b className="text-indigo-800">Choisissez votre notaire</b> pour gérer votre affaire immobilière</p>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <Link to="/onboard/achat"
                                type="button"
                                className="box-content mt-5 no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Continuer (gratuitement)
                                <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                            </Link>
                            <br /><br />
                            <a href="#section1" className="text-slate-800">Comment ça marche ?</a>
                        </div>
                    </div>
                    <div className="w-full sm:basis-1/2 p-0 lg:p-5 ">
                        <div className="overflow-hidden">
                            <img className="w-full rounded-lg" src={HommeSouriant} alt="trouver un notaire pour votre achat" />
                        </div>
                    </div>
                </header>

                {/** section SEO */}
                <section class=" mt-4 mb-4 flex flex-row flex-wrap justify-center items-center">
                    <div className="w-full sm:basis-1/2  p-4 ">
                        <img alt="trouvez un notaire pour votre achat immobilier" src={AchatAvantage2} className="rounded overflow-hidden w-4/5" />
                    </div>
                    <div className="w-full sm:basis-1/2  p-4">
                        <h2 className="font-bold">Besoin <span className="text-indigo-800">d'un notaire immobilier</span> pour vous représenter ?</h2>
                        <p>
                            Opter pour des notaires distincts lors d'une transaction immobilière est un choix judicieux qui favorise une représentation équitable, des conseils personnalisés et une sécurité accrue. <br /><br />
                            Cette possibilité <b>permet d'avoir un interlocuteur de confiance</b> qui comprend les besoins spécifiques et les guide tout au long du processus de vente ou d'achat.


                            <br /><br />
                            Cette approche, qui n'entraîne <b>aucun surcoût</b>, <b>garantit une transaction sereine et sécurisée</b>, bénéfique pour l'acheteur comme pour le vendeur.
                            <br />
                            Pour trouver un notaire gratuitement pour représenter vos intêrets d'acheteur dans votre achat immobilier vous pouvez débuter votre dossier Clotere et suivre l'avancée de votre dossier chez le notaire.

                        </p>
                        <Link to="/onboard/achat"
                            type="button"
                            className="no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Trouvez mon notaire acheteur
                            <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                        </Link>
                    </div>

                </section>

                {/** section ccm **/}
                <section id='section1' className="mt-20 mb-20">
                    <Container>
                        <div class="container mx-auto px-[15px] h-full">
                            <div class="flex flex-col justify-between items-start gap-[30px]">
                                <div class="py-2 px-4 rounded-[20px] text-center bg-emerald-500/30 text-emerald-500">
                                    Trouvez un notaire acheteur simplement
                                </div>
                                <h3 class="text-black leading-[48px] text-[40px] lg:leading-[50px] lg:text-[45px] font-bold sm:max-w-[60%]">Votre notaire immobilier avec Clotere, comment ça marche ?</h3>

                                <div class="grid lg:grid-cols-4 w-full gap-[10px] ">
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                        <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M4.848 2.771A49.144 49.144 0 0 1 12 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97-1.94.284-3.916.455-5.922.505a.39.39 0 0 0-.266.112L8.78 21.53A.75.75 0 0 1 7.5 21v-3.955a48.842 48.842 0 0 1-2.652-.316c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97Z" clip-rule="evenodd" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Répondez à quelques questions</h3>
                                            <p>Nous avons besoin de quelques informations sur vous et votre achat pour constituer votre dossier.</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                        <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5Zm6.61 10.936a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                                                <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Complétez votre dossier</h3>
                                            <p>Ajoutez dans votre espace sécurisé les documents nécessaires à votre dossier chez le notaire.</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                        <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clip-rule="evenodd" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Choisissez votre notaire</h3>
                                            <p>A vous de choisir votre notaire pour gérer votre achat immobilier. 100% à distance ou à proximité de chez vous c’est vous qui choisissez</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">

                                        <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clip-rule="evenodd" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Obtenez vos actes notariés</h3>
                                            <p>Faites vos rendez-vous en visio ou présentiel, et obtenez directement notre plateforme vos documents notariés.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col gap-3 mt-5">
                                <div className="flex -space-x-2 overflow-hidden">
                                    <img
                                        alt="trouver mon notaire acheteur"
                                        src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                        className="inline-block size-10 rounded-full ring-2 ring-white"
                                    />
                                    <img
                                        alt="notaire pour votre achat immobilier"
                                        src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                        className="inline-block size-10 rounded-full ring-2 ring-white"
                                    />
                                    <img
                                        alt="trouver mon notaire"
                                        src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                                        className="inline-block size-10 rounded-full ring-2 ring-white"
                                    />
                                    <img
                                        alt="trouver un notaire"
                                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                        className="inline-block size-10 rounded-full ring-2 ring-white"
                                    />
                                </div>
                                <div className="">
                                    <p className="text-lg">Votre conseiller dédié Clotere se fera un plaisir de répondre à toutes vos questions, tout au long de votre démarche </p>
                                </div>

                            </div>
                            <Link to="/onboard/achat"
                                type="button"
                                className="box-content no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Débuter mon dossier (gratuitement)
                                <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                            </Link>
                        </div>
                    </Container>
                </section>

                {/** section Blog */}
                <section class=" mt-4 mb-4 flex flex-row flex-wrap justify-center items-center">
                    <div className="bg-white py-24 sm:py-20 ">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <div className="mx-auto max-w-2xl text-center">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Bien comprendre votre passage chez le notaire pour <span className="font-bold text-indigo-800">votre achat immobilier</span></h2>

                            </div>
                            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 ">
                                {posts.map((post) => (
                                    <article key={post.id} className="flex flex-col items-start justify-between overflow-hidden bg-white shadow sm:rounded-lg p-3">
                                        <div className="relative w-full">
                                            <img
                                                alt=""
                                                src={post.imageUrl}
                                                className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                            />
                                            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                        <div className="max-w-xl">
                                            <div className="mt-8 flex items-center gap-x-4 text-sm">
                                                <p
                                                    className="relative z-10 rounded-full bg-emerald-50 px-3 py-1.5 font-medium text-emerald-600 hover:bg-gray-100"
                                                >
                                                    {post.category.title}
                                                </p>
                                            </div>
                                            <div className="group relative">
                                                <h3 className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600">
                                                    <a href={post.href} target='blank' className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600 no-underline">
                                                        <span className="absolute inset-0" />
                                                        {post.title}
                                                    </a>
                                                </h3>
                                                <p className="hidden sm:block mt-0 text-md leading-6 text-gray-600">{post.description}</p>
                                                <a href={post.href} target='blank' className="btn-main no-underline">Lire l'article</a>
                                            </div>
                                        </div>
                                    </article>
                                ))}
                            </div>
                            <div className="mx-auto max-w-2xl text-center">
                                <a href="https://clotere.framer.website/notaire-et-immobilier?utm_source=clotere" target="blank"
                                    type="button"
                                    className="mt-5 no-underline inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Consultez nos fiches pratiques notaire et immobilier
                                    <ArrowRightIcon aria-hidden="true" className="-mr-0.5 size-5" />
                                </a>
                            </div>


                        </div>

                    </div>

                </section>




            </Container>
            <BlocCta />
            <Faq />


            <Footer />
        </>
    );
}

export default Acheter;