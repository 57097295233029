import React from "react";
const posts = [
  {
    id: 1,
    title: 'Les frais de notaire à prévoir lors de la vente d\'un bien immobilier',
    href: 'https://clotere.framer.website/blog/quels-sont-les-frais-%C3%A0-pr%C3%A9voir-lors-de-la-vente-d-un-bien-immobilier',
    description: "Allant jusqu'à 6% du prix d'achat de votre bien, les frais de notaire représente un poste de dépense important. Bien anticipé ce coût est important, on vous explique tout sur ces frais de notaire.",
    imageUrl:
      'https://framerusercontent.com/images/oHanIMlzl6HOQ0CmHjJrGGa0Wo.png',
    category: { title: 'Immobilier', href: '#' },
  },
  {
    id: 2,
    title: 'Les clauses suspensives, finalement qu\'est-ce que c\'est ?',
    href: 'https://clotere.framer.website/blog/les-clauses-suspensives-qu-est-ce-que-c-est',
    description: "Si vous achetez un bien immobilier vous entendrez forcément parlé des clauses suspensives 🤔 ! Ne vous inquiétez pas c'est très simple, on vous explique tout dans cet article.",
    imageUrl:
      'https://framerusercontent.com/images/7qUTqENEJz4uogCoIgtjNUWQA.webp',
    category: { title: 'Immobilier', href: '#' },
  },
  {
    id: 3,
    title: 'Quel est le rôle du notaire lors d\'une transaction immobilière ?',
    href: 'https://clotere.framer.website/blog/le-r%C3%B4le-du-notaire-dans-la-vente-d-un-bien-immobilier',
    description: "Acquérir un bien immobilier requiert toujours l’intervention d’un notaire, au moins pour réaliser l’acte de vente. Comment faire le choix d’un notaire pour un achat immobilier ? Découvrez nos conseils.",
    imageUrl:
      'https://framerusercontent.com/images/iSpNwoZYeVubhimwPBiD3iJpg8.webp',
    category: { title: 'Immobilier', href: '#' },
  },

]

const donation = [
  {
    id: 1,
    title: 'Le calcul des frais de notaire dans une donation',
    href: 'https://clotere.framer.website/blog/le-calcul-des-frais-de-notaire-d-une-donation',
    description: "La donation est un moyen apprécié pour transmettre une partie de son patrimoine de son vivant, tout en bénéficiant de certains avantages fiscaux. Cependant, elle entraîne des frais spécifiques, notamment ceux du notaire.",
    imageUrl:
      'https://framerusercontent.com/images/2JQxy9wyTG5cTQNwzWFkxQIunI.webp',
    category: { title: 'Donation', href: '#' },
  },
  {
    id: 2,
    title: 'Donation d’un bien avec réserve d’usufruit : avantages et conditions',
    href: 'https://clotere.framer.website/blog/donation-d-un-bien-avec-r%C3%A9serve-d-usufruit-avantages-et-conditions',
    description: "La donation d’un bien immobilier avec réserve d’usufruit est une solution souvent utilisée pour transmettre son patrimoine tout en conservant certains droits sur le bien.",
    imageUrl:
      'https://framerusercontent.com/images/o8sdhGvU9IH5SUlSEAGfTlW3FMs.webp',
    category: { title: 'Donation', href: '#' },
  },
  {
    id: 3,
    title: 'La réserve héréditaire 🤔',
    href: 'https://clotere.framer.website/blog/les-h%C3%A9ritiers-r%C3%A9servataires-dans-une-donation-qu-est-ce-que-c-est',
    description: "Les héritiers réservataires sont les personnes qui, en vertu de la loi, ne peuvent pas être entièrement déshéritées dans une succession.Dans le cadre d’une donation, il s’agit des individus pour lesquels une part minimale de l’héritage doit être réservée",
    imageUrl:
      'https://framerusercontent.com/images/j1aOH4XhnLP0Mn2poud90iFA0.png',
    category: { title: 'Donation', href: '#' },
  },

]

const pma = [
  {
    id: 1,
    title: 'Etapes de votre reconnaissance conjointe d\'enfant chez le notaire',
    href: 'https://clotere.framer.website/blog/etapes-de-votre-reconnaissance-conjointe-d-enfant-chez-le-notaire',
    imageUrl:
      'https://framerusercontent.com/images/l6iVe6tux07ZrzElylWKsrNRLI.jpg',
    category: { title: 'Pma' },
  },
  {
    id: 2,
    title: 'Les documents nécessaires chez le notaire pour la reconnaissance conjointe d\'enfant',
    href: 'https://clotere.framer.website/blog/les-documents-n%C3%A9cessaires-chez-le-notaire-pour-la-reconnaissance-conjointe-d-enfant',
    imageUrl:
      'https://framerusercontent.com/images/5fCNCXDA6WpRM2ekHi6OhRcubac.png',
    category: { title: 'PMA', href: '#' },
  },
  {
    id: 3,
    title: 'Les avantages juridiques d\'une reconnaissance conjointe d\'enfant, pour les deux membres du couple',
    href: 'https://clotere.framer.website/blog/les-avantages-juridiques-d-une-reconnaissance-conjointe-d-enfant-pour-les-deux-membres-du-couple',
    imageUrl:
      'https://framerusercontent.com/images/Ivn3R7O1DfdccqJKvwwvpFuhA30.jpg?scale-down-to=4096',
    category: { title: 'PMA', href: '#' },
  },

]
const mariage = [
  {
    id: 1,
    title: 'Pourquoi choisir un contrat de mariage ?',
    href: 'https://clotere.framer.website/blog/pourquoi-choisir-un-contrat-de-mariage',
    imageUrl:
      'https://framerusercontent.com/images/0Z9V4g0NZJY7R8ghGpkF77O4skw.jpg?scale-down-to=4096',
    category: { title: 'Notaire et mariage' },
  },
  {
    id: 2,
    title: 'Les étapes pour établir un contrat de mariage',
    href: 'https://clotere.framer.website/blog/les-%C3%A9tapes-pour-%C3%A9tablir-un-contrat-de-mariage',
    imageUrl:
      'https://framerusercontent.com/images/FYofpfluGLFGSG4mRnk7Uqvxt9A.jpg',
    category: { title: 'Notaire et mariage', href: '#' },
  },
  {
    id: 3,
    title: 'Contrat de mariage : idées reçues et vérités',
    href: 'https://clotere.framer.website/blog/contrat-de-mariage-id%C3%A9es-re%C3%A7ues-et-v%C3%A9rit%C3%A9s',
    imageUrl:
      'https://framerusercontent.com/images/vfkk44Hcwfqh5zgavTJso1NHLg.jpg?scale-down-to=4096',
    category: { title: 'Notaire et mariage', href: '#' },
  },

]


function Articles(args) {

  if(args.type === "donation"){
    return(
      <>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 ">
          {donation.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between overflow-hidden bg-white shadow sm:rounded-lg p-3">
              <div className="relative w-full">
                <img
                  alt=""
                  src={post.imageUrl}
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="mt-8 flex items-center gap-x-4 text-sm">
                  <p
                    className="relative z-10 rounded-full bg-emerald-50 px-3 py-1.5 font-medium text-emerald-600 hover:bg-gray-100"
                  >
                    {post.category.title}
                  </p>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href} target='blank' className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600 no-underline">
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                 
                </div>
              </div>
            </article>
          ))}
        </div>
      </>
    )
  }else if(args.type === "pma"){
    return(
      <>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 ">
          {pma.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between overflow-hidden bg-white shadow sm:rounded-lg p-3">
              <div className="relative w-full">
                <img
                  alt=""
                  src={post.imageUrl}
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="mt-8 flex items-center gap-x-4 text-sm">
                  <p
                    className="relative z-10 rounded-full bg-emerald-50 px-3 py-1.5 font-medium text-emerald-600 hover:bg-gray-100"
                  >
                    {post.category.title}
                  </p>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href} target='blank' className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600 no-underline">
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                 
                </div>
              </div>
            </article>
          ))}
        </div>
      </>
    )
  }else if(args.type === "mariage"){
    return(
      <>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 ">
          {mariage.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between overflow-hidden bg-white shadow sm:rounded-lg p-3">
              <div className="relative w-full">
                <img
                  alt=""
                  src={post.imageUrl}
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="mt-8 flex items-center gap-x-4 text-sm">
                  <p
                    className="relative z-10 rounded-full bg-emerald-50 px-3 py-1.5 font-medium text-emerald-600 hover:bg-gray-100"
                  >
                    {post.category.title}
                  </p>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href} target='blank' className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600 no-underline">
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                 
                </div>
              </div>
            </article>
          ))}
        </div>
      </>
    )
  } else{
    return (
      <>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 ">
          {posts.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between overflow-hidden bg-white shadow sm:rounded-lg p-3">
              <div className="relative w-full">
                <img
                  alt=""
                  src={post.imageUrl}
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="mt-2 flex items-center gap-x-4 text-sm">
                  <p
                    className="mb-0 relative z-10 rounded-full bg-emerald-50 px-3 py-1.5 font-medium text-emerald-600 hover:bg-gray-100"
                  >
                    {post.category.title}
                  </p>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href} target='blank' className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600 no-underline">
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                </div>
              </div>
            </article>
          ))}
        </div>
      </>
    );
  }
  
}

export default Articles;