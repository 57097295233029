import React, { useState } from "react";
/** images */
import { ArrowsPointingOutIcon, HomeIcon, InformationCircleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { CurrencyEuroIcon } from "@heroicons/react/24/solid";




function CardAppartementHorizontal(args) {

    return (
        <>
            {/** card */}
            <div className="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-xl dark:hover:shadow-xl dark:shadow-gray-700 dark:hover:shadow-gray-700 overflow-hidden ease-in-out duration-500">
                <div className="flex flex-col sm:flex-row gap-3">
                
                {args.annonce.photos ? (<>
                    <div className="relative  sm:basis-1/4">
                    <img src={args.annonce.photos[0].thumbnails.large.url} alt="Photo de votre annonce" className="w-full max-h-44 min-h-full sm:w-80 sm:h-auto sm:max-h-44" />
                    <div className="absolute top-4 end-4">
                        <span className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-sm font-bold text-purple-700 ring-1 ring-inset ring-purple-700/10">
                            {args.annonce.type}
                        </span>
                    </div>
                    </div>
                </>) :(
                    <><div className="relative sm:basis-1/4 bg-slate-50 text-center">Pas de photo</div></>
                )}
                <div className="p-6  sm:basis-3/4 relative">
                <div className="absolute top-4 end-4">
                        <div className="mt-1 flex items-center gap-x-1.5">
                            {args.annonce.statut === "a valider" &&
                            <>
                            <div className="flex-none rounded-full bg-orange-500/20 p-1"><div className="size-1.5 rounded-full bg-orange-500" /></div>
                            <p className="text-xs/5 text-gray-500 mb-0">En cours de validation</p>
                            </>}
                            {args.annonce.statut === "online" &&
                            <>
                            <div className="flex-none rounded-full bg-green-500/20 p-1"><div className="size-1.5 rounded-full bg-green-500" /></div>
                            <p className="text-xs/5 text-gray-500 mb-0">En ligne</p>
                            </>}
                            
                            </div>
                    </div>
                    <div className="pb-6">
                        <p className="text-xl text-black no-underline font-bold hover:text-emerald-600  ease-in-out duration-500 mb-0">{args.annonce.titre} - {args.annonce.surface} m² - {args.annonce.ville}</p>
                        <p className="text-base text-slate-800 truncate"></p>
                    </div>
                    <ul className="py-2 px-0 border-y border-slate-100 dark:border-gray-800 flex flex-col gap-3 sm:gap-0 sm:flex-row sm:items-center justify-start list-none">
                    <li className="flex items-center  justify-start basis-1/3">
                            <CurrencyEuroIcon className="text-emerald-300 mr-1 size-6" />
                            <span>{args.annonce.prix}€</span>
                        </li>
                        <li className="flex items-center  justify-start basis-1/3">
                            <ArrowsPointingOutIcon className="text-emerald-300 mr-1 size-6" />
                            <span>{args.annonce.surface} m2</span>
                        </li>
                        <li className="flex items-center basis-1/3 justify-start">
                            <HomeIcon className="text-emerald-300 mr-1 size-6" />
                            <span>{args.annonce.nb_chambre} chambre(s)</span>
                        </li>
                        <li className="flex items-center basis-1/3 justify-start">
                            <InformationCircleIcon className="text-emerald-300 mr-1 size-6" />
                            <span>{args.annonce.nb_piece} piece(s)</span>
                        </li>
                    </ul>
                    {args.annonce.statut === "online" &&<><Link to="" target="_blank" className="btn-main no-underline">Voir l'annonce</Link></>}
                </div>

                </div>{/**end flec */}
                
                
                
            </div>
        </>
    );
}

export default CardAppartementHorizontal;