import React, { useState, useEffect } from "react";
import { useMemberstack, } from "@memberstack/react";
/** images */
import { Link } from "react-router-dom";
//firebase
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { initializeApp } from "firebase/app";
//component 
import NavVente from "./NavVente";
import AlertComponent from "../components/Alert"
//img et icn
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import {CheckIcon } from '@heroicons/react/20/solid'
import FooterVente from "./FooterVente";



function PublierAnnonce() {
    const memberstack = useMemberstack();
    const [member, setMember] = useState(null);
    const [fonciere, setFonciere] = useState(null);
    const [prenom, setPrenom] = useState(null);
    const [nom, setNom] = useState(null);
    const [telephone, setTelephone] = useState(null);
    const [titre, setTitre] = useState(null);
    const [description, setDescription] = useState(null);
    const [adresse, setAdresse] = useState(null);
    const [cp, setCp] = useState(null);
    const [ville, setVille] = useState(null);
    const [type, setType] = useState(null);
    const [prix, setPrix] = useState(null);
    const [surface, setSurface] = useState(null);
    const [nb_piece, setNbPiece] = useState(null);
    const [nb_chambre, setNbChambre] = useState(null);
    const [ascenceur, setAscenceur] = useState("non");
    const [parking, setParking] = useState("non");
    const [cave, setCave] = useState("non");
    const [balcon, setBalcon] = useState("non");
    const [jardin, setJardin] = useState("non");
    const [terrasse, setTerrase] = useState("non");
    const [douche, setDouche] = useState("non");
    const [baignoire, setBaignoire] = useState("non");
    const [charges, setCharges] = useState(null);
    const [loyer, setLoyer] = useState("");
    const [locchargesmensuelles, setLocchargesmensuelles] = useState("");
    const [classeEnergetique, setClasseEnergetique] = useState(null);
    const [ges, setGes] = useState(null);
    const [vendu, setVendu] = useState(null);
    const [copro, setCopro] = useState(null);
    const [formError, setFormError] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState("");
    //step
    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);
    const [step4, setStep4] = useState(false);
    const [step5, setStep5] = useState(false);
    const [step6, setStep6] = useState(false);
    const [stepEnd, setStepEnd] = useState(false);
    //photo
    const [storage, setStorage] = useState([]);

    //set member if logged
    useEffect(() => {
        memberstack.getCurrentMember()
            .then(({ data: member }) => setMember(member))
    }, []);
   


    //crea compte memberstack
    const handleInscription = (event) => {
        event.preventDefault();
        const email = event.target.email.value;
        const password = event.target.password.value;
        const prenom = event.target.prenom.value;
        const nom = event.target.nom.value;
        const telephone = event.target.telephone.value;
        setPrenom(prenom);
        setNom(nom);
        setTelephone(telephone);

        {
            memberstack.signupMemberEmailPassword({
                email: email,
                password: password
            }).then(result => {
                //console.log("result", result);
                setMember(result.data.member);
                setStep1(false);
                setStep2(true);
            })
                .catch(er => {
                    console.log("erreur memberstack", er);
                    setFormError(true);
                    if (er.code === "email-already-in-use") {
                        setFormErrorMessage("Cet email existe déjà, veuillez vous connecter");
                    } else {
                        setFormErrorMessage("Une erreur s'est produite veuillez recommencer");
                    }


                });
        }

    }


    const handleSubmitInfoBase = (event) => {
        event.preventDefault();
        const titre = event.target.titre.value;
        const description = event.target.description.value;
        const adresse = event.target.adresse.value;
        const cp = event.target.cp.value;
        const ville = event.target.ville.value;
        setTitre(titre);
        setDescription(description);
        setAdresse(adresse);
        setCp(cp);
        setVille(ville);
        setStep2(false);
        setStep3(true);
    }
    const handleSubmitCaracteristique = (event) => {
        event.preventDefault();
        const prix = event.target.prix.value;
        const surface = event.target.surface.value;
        const nb_piece = event.target.nb_piece.value;
        const nb_chambre = event.target.nb_chambre.value;
        setPrix(prix);
        setSurface(surface);
        setNbPiece(nb_piece);
        setNbChambre(nb_chambre);
        setStep3(false);
        setStep4(true);
    }
    const handleSubmitPerformance = (event) => {
        event.preventDefault();
        setStep4(false);
        setStep5(true);
    }

    const handleSubmitCharges = (event) => {
        event.preventDefault();
        setFonciere(event.target.fonciere.value);
        setCharges(event.target.charges.value);
        setCopro(event.target.copro.value);
        if (vendu === "louer") {
            setLoyer(event.target.loyer.value);
            setLocchargesmensuelles(event.target.locchargesmensuelles.value);
        }
        setStep5(false);
        setStep6(true);
    }
    const handleSubmitAnnonce = (event) => {
        event.preventDefault();
        const sendToZapier = async () => {
            const zapierURL = "https://hooks.zapier.com/hooks/catch/7206204/28oc2ko/"; // 👈 Specify your Zapier Webhook URL here
            try {
              const response = await fetch(zapierURL, {
                method: "POST",
                mode: "cors",
                body: JSON.stringify({member,nom,prenom,telephone,titre,description,type,adresse,cp,ville,prix,surface,nb_piece,nb_chambre,ascenceur,parking,cave,balcon,terrasse,jardin,douche,baignoire,classeEnergetique,ges,fonciere,charges,vendu,loyer,locchargesmensuelles,copro,firebaseUrls})
              });
              const res = await response.json();
              console.log(res);
              if(res){
                setStep6(false);
                setStepEnd(true);
              }
            } catch (e) {
              console.log(e);
            }
          };
          sendToZapier();
    }
    //firebase pour upload diag
    const firebaseConfig = {
        apiKey: "AIzaSyBx-T0tuEcSlSFsPF1zyeYQo3pZG-zytz8",
        authDomain: "clotere-33ee1.firebaseapp.com",
        projectId: "clotere-33ee1",
        storageBucket: "clotere-33ee1.appspot.com",
        messagingSenderId: "488961682641",
        appId: "1:488961682641:web:b89810aa37cda07d7169f0"
    };
    var appFirebase = null;

    //firebase initialisation
    useEffect(() => {
        const fetchData = async () => {
            appFirebase = initializeApp(firebaseConfig);
            setStorage(getStorage(appFirebase));
        }
        fetchData()
    }, []);


    const [photos, setPhotos] = useState(Array(8).fill(null)); // Pour stocker les fichiers
    const [previews, setPreviews] = useState(Array(8).fill(null)); // Pour les prévisualisations
    const [loaders, setLoaders] = useState(Array(8).fill(false)); // Pour les loaders
    const [firebaseUrls, setFirebaseUrls] = useState(Array(8).fill(null)); // URLs Firestore
    const [uploadErrors, setUploadErrors] = useState(Array(8).fill(false)); // Pour les erreurs

    const handleUploadPhoto = (event, index) => {
        const file = event.target.files[0];
        if (!file) return;

        const fileSize = file.size;
        const fileMb = fileSize / 1024 ** 2;

        if (fileMb >= 5) { // Limite de 5 MB
            alert("Votre fichier est trop volumineux. Taille maximale 5MB.");
            event.target.value = ""; // Efface le fichier
        } else {
            const newLoaders = [...loaders];
            newLoaders[index] = true;
            setLoaders(newLoaders);

            const newPreviews = [...previews];
            newPreviews[index] = URL.createObjectURL(file);
            setPreviews(newPreviews);

            const handleUpload = async () => {
                try {
                    const storageRef = ref(storage, file.name); // Remplacez `storage` par votre config
                    uploadBytes(storageRef, file)
                        .then((snapshot) => {
                            getDownloadURL(snapshot.ref)
                                .then((url) => {
                                    const newFirebaseUrls = [...firebaseUrls];
                                    newFirebaseUrls[index] = url;
                                    setFirebaseUrls(newFirebaseUrls);

                                    const newPhotos = [...photos];
                                    newPhotos[index] = file;
                                    setPhotos(newPhotos);

                                    const updatedLoaders = [...loaders];
                                    updatedLoaders[index] = false;
                                    setLoaders(updatedLoaders);
                                })
                                .catch((error) => {
                                    console.error("Erreur URL Firestore", error);
                                    const newUploadErrors = [...uploadErrors];
                                    newUploadErrors[index] = true;
                                    setUploadErrors(newUploadErrors);

                                    const updatedLoaders = [...loaders];
                                    updatedLoaders[index] = false;
                                    setLoaders(updatedLoaders);
                                });
                        })
                        .catch((error) => {
                            console.error("Erreur Firebase", error);
                            const newUploadErrors = [...uploadErrors];
                            newUploadErrors[index] = true;
                            setUploadErrors(newUploadErrors);

                            const updatedLoaders = [...loaders];
                            updatedLoaders[index] = false;
                            setLoaders(updatedLoaders);
                        });
                } catch (e) {
                    console.error("Erreur lors de l'upload", e);
                }
            };

            handleUpload();
        }
    };
    const handleRemovePhoto = (index) => {
        const newPhotos = [...photos];
        const newPreviews = [...previews];

        newPhotos[index] = null;
        newPreviews[index] = null;

        setPhotos(newPhotos);
        setPreviews(newPreviews);
    };
    const renderPhotoInputs = () => {
        return Array.from({ length: 8 }, (_, index) => (

            <label
                key={index}
                className="flex my-2 w-[150px] h-[150px] rounded-md border-2 border-slate-200 flex-col justify-center items-center overflow-hidden"
            >
                <span className="mb-2">Photo {index + 1}</span>
                {photos[index] === null && (
                    <>
                        <span className="flex w-content px-3 py-2 text-sm bg-indigo-100 text-indigo-700 font-bold rounded-full">
                            + Ajouter
                            <input
                                onChange={(event) => handleUploadPhoto(event, index)}
                                accept="image/png, image/jpeg"
                                type="file"
                                className="hidden"
                            />
                        </span>
                    </>
                )}
                {loaders[index] && (
                    <>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="animate-spin h-5 w-5"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </>
                )}
                {photos[index] !== null && (
                    <>
                        <img
                            className="w-auto max-w-[90%] h-[120px]"
                            src={previews[index]}
                            alt={`Prévisualisation ${index + 1}`}
                        />
                        <button
                            onClick={() => handleRemovePhoto(index)}
                            className="text-red-400 no-underline text-sm mt-2 flex items-center"
                        >
                            Supprimer
                        </button>
                    </>
                )}
            </label>
        ));
    };

    return (
        <>
            <div className="bg-gradient-to-t from-teal-100 to-teal-50">
                <NavVente />
                <div className="flex min-h-screen flex-1">
                    <div className="relative hidden sm:flex sm:basis-1/3 justify-center px-4 py-12 mx-auto w-full max-w-lg items-center">
                        <div className="sm:max-w-lg">
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-xl">Votre annonce immobilier entre particuliers gratuitement</p>
                            <p className="mt-3 text-sm leading-5 text-gray-600">
                                Pour maximiser vos chances de vente entre particuliers, nous diffuserons votre annonce sur les principaux portails de vente immobilier.
                            </p>
                            <dl className="grid grid-cols-1 gap-x-8 gap-y-8 lg:gap-y-8">
                                <div className="relative pl-12 pt-2.5">
                                    <dt className="text-sm font-semibold text-gray-900">
                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                        </div>
                                        Publiez votre annonce gratuitement
                                    </dt>
                                </div>
                                <div className="relative pl-12 pt-1.5">
                                    <dt className="text-sm font-semibold leading-7 text-gray-900">
                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                        </div>
                                        Modifications gratuites
                                    </dt>
                                </div>
                                <div className="relative pl-12 pt-1.5">
                                    <dt className="text-sm font-semibold leading-7 text-gray-900">
                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                        </div>
                                        Photos en illimités
                                    </dt>
                                </div>
                                <div className="relative pl-12 pt-1.5">
                                    <dt className="text-sm font-semibold leading-7 text-gray-900">
                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                        </div>
                                        Diffusion sur les portails immobilier
                                    </dt>
                                </div>
                                <div className="relative pl-12 pt-2.5">
                                    <dt className="text-sm font-semibold leading-7 text-gray-900">
                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                        </div>
                                        Aucune diffusion de vos coordonnées
                                    </dt>
                                </div>
                            </dl>
                        </div>
                    </div>
                    <div className="bg-white flex basis-2/3 justify-center items-center px-4 py-12">
                        <div className="mx-auto w-full max-w-lg ">
                            {/** Inscription */}
                            {step1 === true &&
                                <>

                                    <div>
                                        <h2 className=" text-2xl/9 font-bold tracking-tight text-gray-900">Créer votre compte</h2>
                                        <p className="mt-2 text-base text-gray-500">
                                            Déjà inscrit ?{' '}
                                            <Link to="/connexion" className="font-semibold text-indigo-600 hover:text-indigo-500">
                                                Connectez-vous
                                            </Link>
                                        </p>
                                    </div>

                                    <div className="mt-10">
                                        <div>
                                            <form action="#" method="POST" className="space-y-6" onSubmit={handleInscription}>
                                                {formError === true && <><AlertComponent type="error" message={formErrorMessage} /></>}
                                                

                                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                                    <div className="sm:col-span-3">
                                                        <label className="block text-base font-medium text-gray-900">
                                                            Nom*
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                name="nom"
                                                                type="text"
                                                                autoComplete="given-name"
                                                                maxLength="20"
                                                                className="input-clotere"
                                                                required
                                                                defaultValue={nom != null ? nom : ''}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="sm:col-span-3">
                                                        <label htmlFor="last-name" className="block text-base font-medium text-gray-900">
                                                            Prénom*
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                name="prenom"
                                                                type="text"
                                                                autoComplete="prenom"
                                                                maxLength="20"
                                                                className="input-clotere"
                                                                required
                                                                defaultValue={prenom != null ? prenom : ''}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="telephone" className="block text-base font-medium text-gray-900">
                                                        Téléphone*
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            name="telephone"
                                                            type="text"
                                                            autoComplete="phone"
                                                            maxLength="20"
                                                            className="input-clotere"
                                                            required
                                                            defaultValue={telephone != null ? telephone : ''}
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div>
                                                    <label htmlFor="email" className="block text-base font-medium text-gray-900">
                                                        Email*
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            required
                                                            autoComplete="email"
                                                            className="input-clotere"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="password" className="block text-base font-medium text-gray-900">
                                                        Mot de passe*
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            id="password"
                                                            name="password"
                                                            type="password"
                                                            required
                                                            autoComplete="current-password"
                                                            className="input-clotere"
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <button
                                                        type="submit"
                                                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        S'inscrire
                                                    </button>
                                                    <p className="mt-10 text-center text-base text-gray-500">
                                                        En cliquant sur S'inscrire, vous acceptez <a href="https://clotere.fr/info/cgu" target="blank">les conditions générales d'utilisation </a>de Clotere.{' '}

                                                    </p>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </>
                            }
                            {/** Annonce info 1 */}
                            {step2 === true &&
                                <>
                                    <div>
                                        <p className="text-sm font-medium text-slate-500 mb-1">Étapes <span className="text-indigo-800">2</span>/6</p>
                                        <h2 className="text-2xl/9 font-bold tracking-tight text-gray-900">Informations de base</h2>
                                    </div>

                                    <div className="mt-10">
                                        <form action="#" method="POST" className="space-y-6" onSubmit={handleSubmitInfoBase}>
                                            <div>
                                                <label className="block text-base font-medium text-gray-900">
                                                    Titre de votre annonce<sup>*</sup>
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        required
                                                        id="titre"
                                                        name="titre"
                                                        type="text"
                                                        className="input-clotere"
                                                        maxLength={100}
                                                        defaultValue={titre != null ? titre : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label className="block text-base font-medium text-gray-900">
                                                    Description de votre annonce<sup>*</sup>
                                                </label>
                                                <div className="mt-2">
                                                    <textarea
                                                        required
                                                        name="description"
                                                        rows={3}
                                                        className="input-clotere"
                                                        maxLength={700}
                                                        defaultValue={description != null ? description : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label className="block text-base font-medium text-gray-900">
                                                    Type de bien
                                                </label>
                                                <div className="mt-2">
                                                    <ul className="grid w-full gap-1 md:grid-cols-2 p-0">
                                                        <li>
                                                            <input type="radio" id="appart" name="type" className="hidden peer" required onClick={() => { setType("appartement"); }} />
                                                            <label htmlFor="appart" className="radio-button">
                                                                Appartement
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input type="radio" id="maison" name="type" className="hidden peer" required onClick={() => { setType("maison"); }} />
                                                            <label htmlFor="maison" className="radio-button">
                                                                Maison
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input type="radio" id="park" name="type" className="hidden peer" required onClick={() => { setType("parking"); }} />
                                                            <label htmlFor="park" className="radio-button">
                                                                Parking
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input type="radio" id="terrain" name="type" className="hidden peer" required onClick={() => { setType("terrain"); }} />
                                                            <label htmlFor="terrain" className="radio-button">
                                                                Terrain
                                                            </label>
                                                        </li>
                                                        <li>
                                                    </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div>
                                                <label className="block text-base font-medium text-gray-900">
                                                    Où se trouve le logement ?<sup>*</sup>
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        required
                                                        name="adresse"
                                                        placeholder="Adresse"
                                                        className="input-clotere"
                                                        maxLength={60}
                                                        defaultValue={adresse != null ? adresse : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                                <div className="sm:col-span-3">
                                                    <div className="">
                                                        <input
                                                            name="cp"
                                                            type="text"
                                                            autoComplete="given-name"
                                                            maxLength="5"
                                                            className="input-clotere"
                                                            required
                                                            placeholder="Code postal"
                                                            defaultValue={cp != null ? cp : ''}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <div className="">
                                                        <input
                                                            placeholder="Ville"
                                                            name="ville"
                                                            type="text"
                                                            autoComplete="ville"
                                                            maxLength="20"
                                                            className="input-clotere"
                                                            required
                                                            defaultValue={ville != null ? ville : ''}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    className="btn-indigo"
                                                >
                                                    Valider
                                                </button>
                                            </div>

                                        </form>

                                    </div>
                                </>
                            }
                            {/** Annonce info caractéristique */}
                            {step3 === true &&
                                <>
                                    <div>
                                        <p className="text-sm font-medium text-slate-500 mb-1">Étapes <span className="text-indigo-800">3</span>/6</p>
                                        <h2 className=" text-2xl/9 font-bold tracking-tight text-gray-900">Caractéristiques</h2>
                                    </div>

                                    <div className="mt-10">
                                        <form action="#" method="POST" className="space-y-6" data-ms-form="signup" onSubmit={handleSubmitCaracteristique}>
                                            {formError === true && <><AlertComponent type="error" message={formErrorMessage} /></>}
                                            <div>
                                                <label className="block text-base font-medium text-gray-900">
                                                    Prix de vente en €<sup>*</sup>
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        required
                                                        name="prix"
                                                        type="number"
                                                        className="input-clotere"
                                                        defaultValue={prix != null ? prix : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label className="block text-base font-medium text-gray-900">
                                                    Surface en m2<sup>*</sup>
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        required
                                                        name="surface"
                                                        type="number"
                                                        className="input-clotere"
                                                        defaultValue={surface != null ? surface : ''}
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <label className="block text-base font-medium text-gray-900">
                                                    Nombre de pièce<sup>*</sup>
                                                </label>
                                                <p className="m-0 text-sm text-slate-500">Comptez toutes les pièces sauf la cuisine, les salles d’eau et de bains ainsi que les toilettes.</p>
                                                <div className="mt-2">
                                                    <input
                                                        required
                                                        name="nb_piece"
                                                        type="number"
                                                        className="input-clotere"
                                                        defaultValue={nb_piece != null ? nb_piece : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label className="block text-base font-medium text-gray-900">
                                                    Nombre de chambre<sup>*</sup>
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        required
                                                        name="nb_chambre"
                                                        type="number"
                                                        className="input-clotere"
                                                        defaultValue={nb_chambre != null ? nb_chambre : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label className="block text-base font-medium text-gray-900">
                                                    Equipement(s)
                                                </label>

                                                <ul className="grid w-full gap-1 grid-cols-3 p-0 mt-2">
                                                    <li>
                                                        <input type="radio" id="ascenceur" name="ascenceur" className="hidden peer"  onClick={() => { setAscenceur("oui"); }} />
                                                        <label htmlFor="ascenceur" className="radio-button">
                                                            Ascenceur
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="parking" name="parking" className="hidden peer"  onClick={() => { setParking("oui"); }} />
                                                        <label htmlFor="parking" className="radio-button">
                                                            Parking
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="cave" name="cave" className="hidden peer"  onClick={() => { setCave("oui"); }} />
                                                        <label htmlFor="cave" className="radio-button">
                                                            Cave
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="balcon" name="balcon" className="hidden peer"  onClick={() => { setBalcon("oui"); }} />
                                                        <label htmlFor="balcon" className="radio-button">
                                                            Balcon
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="terrasse" name="terrasse" className="hidden peer"  onClick={() => { setTerrase("oui"); }} />
                                                        <label htmlFor="terrasse" className="radio-button">
                                                            Terrasse
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="jardin" name="jardin" className="hidden peer"  onClick={() => { setJardin("oui"); }} />
                                                        <label htmlFor="jardin" className="radio-button">
                                                            Jardin
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="douche" name="douche" className="hidden peer"  onClick={() => { setDouche("oui"); }} />
                                                        <label htmlFor="douche" className="radio-button">
                                                            Douche
                                                        </label>
                                                    </li><li>
                                                        <input type="radio" id="baignoire" name="baignoire" className="hidden peer"  onClick={() => { setBaignoire("oui"); }} />
                                                        <label htmlFor="baignoire" className="radio-button">
                                                            Baignoire
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <button
                                                    type="submit"
                                                    className="btn-indigo"
                                                >
                                                    Continuer
                                                </button>
                                            </div>
                                        </form>

                                    </div>
                                </>
                            }

                            {/** performances */}
                            {step4 === true &&
                                <>
                                    <div>
                                        <p className="text-sm font-medium text-slate-500 mb-1">Étapes <span className="text-indigo-800">4</span>/6</p>
                                        <h2 className=" text-2xl/9 font-bold tracking-tight text-gray-900">Performances</h2>
                                    </div>
                                        <form action="#" method="POST" className="space-y-6" data-ms-form="signup" onSubmit={handleSubmitPerformance}>
                                            <AlertComponent type='info' message="Depuis janvier 2022, il est obligatoire d'afficher votre DPE sur votre annonce pour vendre un bien. Ces informations jouent également un rôle clé dans la prise de décision des acquéreurs." />
                                            <div>
                                                <label className="block text-base font-medium text-gray-900">
                                                    Votre diagnostic de performance énergétique (DPE)
                                                </label>

                                                <ul className="grid w-full gap-1 grid-cols-7 p-0 mt-2">
                                                    <li>
                                                        <input type="radio" id="ancien" name="classe" className="hidden peer" required onClick={() => { setClasseEnergetique("A"); }} />
                                                        <label htmlFor="ancien" className="radio-button">
                                                            A
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="b" name="classe" className="hidden peer" required onClick={() => { setClasseEnergetique("B"); }} />
                                                        <label htmlFor="b" className="radio-button">
                                                            B
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="c" name="classe" className="hidden peer" required onClick={() => { setClasseEnergetique("C"); }} />
                                                        <label htmlFor="c" className="radio-button">
                                                            C
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="d" name="classe" className="hidden peer" required onClick={() => { setClasseEnergetique("D"); }} />
                                                        <label htmlFor="d" className="radio-button">
                                                            D
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="e" name="classe" className="hidden peer" required onClick={() => { setClasseEnergetique("E"); }} />
                                                        <label htmlFor="e" className="radio-button">
                                                            E
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="f" name="classe" className="hidden peer" required onClick={() => { setClasseEnergetique("F"); }} />
                                                        <label htmlFor="f" className="radio-button">
                                                            F
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="g" name="classe" className="hidden peer" required onClick={() => { setClasseEnergetique("G"); }} />
                                                        <label htmlFor="g" className="radio-button">
                                                            G
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <label className="block text-base font-medium text-gray-900">
                                                    Emission de gaz à effet de serre (GES)
                                                </label>
                                                <ul className="grid w-full gap-1 grid-cols-7 p-0 mt-2">
                                                    <li>
                                                        <input type="radio" id="gesa" name="ges" className="hidden peer" required onClick={() => { setGes("A"); }} />
                                                        <label htmlFor="gesa" className="radio-button">
                                                            A
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="gesb" name="ges" className="hidden peer" required onClick={() => { setGes("B"); }} />
                                                        <label htmlFor="gesb" className="radio-button">
                                                            B
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="gesc" name="ges" className="hidden peer" required onClick={() => { setGes("C"); }} />
                                                        <label htmlFor="gesc" className="radio-button">
                                                            C
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="gesd" name="ges" className="hidden peer" required onClick={() => { setGes("D"); }} />
                                                        <label htmlFor="gesd" className="radio-button">
                                                            D
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="gese" name="ges" className="hidden peer" required onClick={() => { setGes("E"); }} />
                                                        <label htmlFor="gese" className="radio-button">
                                                            E
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="gesf" name="ges" className="hidden peer" required onClick={() => { setGes("F"); }} />
                                                        <label htmlFor="gesf" className="radio-button">
                                                            F
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="gesg" name="ges" className="hidden peer" required onClick={() => { setGes("G"); }} />
                                                        <label htmlFor="gesg" className="radio-button">
                                                            G
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <button
                                                    type="submit"
                                                    className="btn-indigo"
                                                >
                                                    Continuer
                                                </button>
                                            </div>
                                        </form>

                                    
                                </>
                            }

                            {/** Charges*/}
                            {step5 === true &&
                                <>
                                    <div>
                                        <p className="text-sm font-medium text-slate-500 mb-1">Étapes <span className="text-indigo-800">5</span>/6</p>
                                        <h2 className=" text-2xl/9 font-bold tracking-tight text-gray-900">Charges</h2>
                                    </div>

                                    <div className="mt-10">
                                        <form action="#" method="POST" className="space-y-6" data-ms-form="signup" onSubmit={handleSubmitCharges}>
                                            <div>
                                                <label className="block text-base font-medium text-gray-900">
                                                    Taxe foncière annuelle<sup>*</sup>
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        required
                                                        name="fonciere"
                                                        type="number"
                                                        className="input-clotere"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label className="block text-base font-medium text-gray-900">
                                                    Charges annuelles<sup>*</sup>
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        required
                                                        id="charges"
                                                        name="charges"
                                                        type="number"
                                                        className="input-clotere"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label className="block text-base font-medium text-gray-900">
                                                    Vendu
                                                </label>
                                                <div className="mt-2">
                                                    <ul className="grid w-full gap-1 md:grid-cols-2 p-0">
                                                        <li>
                                                            <input type="radio" id="vide" name="typevente" className="hidden peer" required onClick={() => { setVendu("vide"); }} />
                                                            <label htmlFor="vide" className="radio-button">
                                                                Vide
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input type="radio" id="louer" name="typevente" className="hidden peer" required onClick={() => { setVendu("louer"); }} />
                                                            <label htmlFor="louer" className="radio-button">
                                                                Loué
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {vendu === "louer" && (
                                                <>
                                                    <div>
                                                        <label className="block text-base font-medium text-gray-900">
                                                            Montant du loyer mensuel charges comprises
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                id="loyer"
                                                                name="loyer"
                                                                type="number"
                                                                className="input-clotere"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label className="block text-base font-medium text-gray-900">
                                                            Montant des charges mensuelles
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                id="locchargesmensuelles"
                                                                name="locchargesmensuelles"
                                                                type="number"
                                                                className="input-clotere"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <div>
                                                <label className="block text-base font-medium text-gray-900">
                                                    Syndic de copropriété<sup>*</sup>
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        required
                                                        name="copro"
                                                        type="text"
                                                        className="input-clotere"
                                                        defaultValue={copro != null ? copro : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    type="submit"
                                                    className="btn-indigo"
                                                >
                                                    Continuer
                                                </button>
                                            </div>
                                        </form>

                                    </div>
                                </>
                            }

                            {/** Photo*/}
                            {step6 === true &&
                                <>
                                    <div>
                                        <p className="text-sm font-medium text-slate-500 mb-1">Étapes <span className="text-indigo-800">6</span>/6</p>
                                        <h2 className=" text-2xl/9 font-bold tracking-tight text-gray-900">Vos photos</h2>
                                        <p>C'est ici que tout ce joue ! Ajoutez jusqu'à 8 photos pour votre annonce et donnez envie aux futurs acquéreurs de visiter.</p>
                                    </div>

                                    <div className="mt-10">
                                        <form action="#" method="POST" className="space-y-6" data-ms-form="signup" onSubmit={handleSubmitAnnonce}>
                                            <aside className='flex flex-wrap flex-row gap-3'>
                                                {renderPhotoInputs()}
                                            </aside>
                                            <div>
                                                <button
                                                    type="submit"
                                                    className="btn-indigo"
                                                >
                                                    Continuer
                                                </button>
                                            </div>
                                        </form>

                                    </div>
                                </>
                            }

                            {/** end */}
                            {stepEnd === true &&
                                <>
                                    <div className="mt-10">
                                        <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                                            <CheckIcon aria-hidden="true" className="size-6 text-green-600" />
                                        </div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <h2 as="h3" className=" text-2xl/9 font-bold tracking-tight text-gray-900">
                                                Votre annonce a bien été enregistrée
                                            </h2>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Votre annonce est en cours de vérification, vous recevrez un email lorsquelle sera validée
                                                </p>
                                                <Link to="/app/dashboard-annonce-2" className="btn-indigo no-underline">Accéder à mon espace client</Link>
                                            </div>
                                        </div>

                                    </div>
                                </>
                            }


                        </div>
                    </div>
                </div>

                <FooterVente />
            </div>
        </>
    );
}


export default PublierAnnonce;