import React from "react";
import { Container } from "../annuaire/Container";

function CommentCaMarche() {
    return (
        <section className="mt-20 mb-20">
            <Container>
            <div class="container mx-auto px-[15px] h-full">
                <div class="flex flex-col justify-between items-start gap-[20px]">
                    <p class="py-2 px-4 rounded-[20px] text-center bg-slate-100 font-bold m-0">
                        Comment ça marche ?
                    </p>
                    <h3 class="text-black leading-[38px] !text-[30px] lg:leading-[50px] lg:text-[45px] font-bold max-w-[60%]">Simple et transparent pour tout comprendre</h3>

                    <div class="grid lg:grid-cols-4 w-full gap-[10px] ">
                        <div class="flex flex-col justify-end items-start gap-[10px] bg-gradient-to-r from-slate-200 to-slate-100 p-[20px] rounded-lg w-full   relative overflow-hidden hover:card-hover">
                            <div class="flex flex-col justify-end items-start gap-[10px] sm:max-w-[253px]">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-8 text-teal-600 animate-pulse">
                                    <path fill-rule="evenodd" d="M4.848 2.771A49.144 49.144 0 0 1 12 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97-1.94.284-3.916.455-5.922.505a.39.39 0 0 0-.266.112L8.78 21.53A.75.75 0 0 1 7.5 21v-3.955a48.842 48.842 0 0 1-2.652-.316c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97Z" clip-rule="evenodd" />
                                </svg>
                                <h3 class="text-black font-bold text-[22px] leading-[22px]">Répondez à quelques questions</h3>
                                <p>Nous avons besoin de quelques informations pour créer votre démarche chez le notaire en ligne.</p>
                            </div>
                        </div>
                        <div class="flex flex-col justify-end items-start gap-[10px] bg-slate-100 p-[20px] rounded-lg w-full   relative overflow-hidden hover:card-hover">
                            <div class="flex flex-col justify-end items-start gap-[10px] sm:max-w-[253px]">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-8 text-teal-600 animate-pulse">
                                <path fill-rule="evenodd" d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5Zm6.61 10.936a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                                <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                                </svg>
                                <h3 class="text-black font-bold text-[22px] leading-[22px]">Constituez votre dossier</h3>
                                <p>Obtenez la liste des documents nécessaires à votre démarche et les étapes de votre démarche chez le notaire.</p>
                            </div>
                        </div>
                        <div class="flex flex-col justify-end items-start gap-[10px] bg-slate-100 p-[20px] rounded-lg w-full   relative overflow-hidden hover:card-hover">
                            <div class="flex flex-col justify-end items-start gap-[10px] sm:max-w-[253px]">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-8 text-teal-600 animate-pulse">
                                <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clip-rule="evenodd" />
                                </svg>
                                <h3 class="text-black font-bold text-[22px] leading-[22px]">Choisissez votre notaire</h3>
                                <p>Choisissez le notaire qui sera en charge de votre dossier parmis nos notaires partenaires.</p>
                            </div>
                        </div>
                        <div class="flex flex-col justify-end items-start gap-[10px] bg-gradient-to-r from-slate-100 to-slate-200 p-[20px] rounded-lg w-full   relative overflow-hidden hover:card-hover">

                            <div class="flex flex-col justify-end items-start gap-[10px] sm:max-w-[253px]">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-8 text-teal-600 animate-pulse">
                                <path fill-rule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clip-rule="evenodd" />
                                </svg>
                                <h3 class="text-black font-bold text-[22px] leading-[22px]">Obtenez vos actes notariés</h3>
                                <p>Recevez directement dans votre espace sécurisé Clotere vos documents notariés.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Container>
        </section>

    );
}

export default CommentCaMarche;
