import React from "react";
import { Link } from "react-router-dom";
/** composants **/
import { Container, Row, Col } from "reactstrap";
import Footer from './components/Footer';
import { ChevronRightIcon } from '@heroicons/react/16/solid';
import { Navbar } from './annuaire/Navbar';
import CtaNotaire from './annuaire/CtaNotaire';


function Mentionslegales(args) {
    return (
        <>
            <div>
                <Container className="relative">
                    <Navbar
                        user={args.user}
                        banner={
                            <Link
                                to="https://clotere.framer.website"
                                target="_blank"
                                className="no-underline flex items-center gap-1 rounded-full bg-fuchsia-950/35 px-3 py-0.5 text-sm/6 font-medium text-white data-[hover]:bg-fuchsia-950/30"
                            >
                                👋 Votre notaire en clair !
                                <ChevronRightIcon className="size-4" />
                            </Link>
                        }
                    />


                    <Row>
                        <Col md="12" align="center">
                            <h1 className="mt-5 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Mentions légales</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3"></Col>
                        <Col md="6" className="mb-6">
                            <p>
                                Le présent site internet clotere.fr a été créé en France.

                                <br /><br />L’accès au site, sa consultation et son utilisation sont subordonnés à l’acceptation sans réserve des Conditions Générales d’Utilisation.

                                <br /><br /><b>Le site est édité par :</b>

                                <br /> Clotere, SAS au capital de 1 000,00 euros, immatriculée au registre du commerce et des sociétés de Paris sous le numéro 939 108 692.

                                <br /><br />Siège social :  25 RUE DE PONTHIEU , 75008 PARIS France.

                                <br /><br />La responsable de publication de clotere.fr est Madame Cécile CHELIM.

                                <br /><br /><b>Le site clotere.fr est hébergé par :</b>

                                <br />La société OVH :
                                <br />SAS au capital de 10 069 020 €
                                <br />RCS Lille Métropole 424 761 419 00045
                                <br />Code APE 2620Z
                                <br />N° TVA : FR 22 424 761 419
                                <br />Siège social : 2 rue Kellermann - 59100 Roubaix - France


                                <br /><br />Pour toutes questions sur l’entreprise, vous pouvez nous envoyer vos questions par email à <a href="mailto:contact@clotere.fr">contact@clotere.fr</a>.

                                <br /><br /> Pour simplifier nos procédures, merci de bien vouloir indiquer dans votre réquisition l’adresse email à laquelle vous faire parvenir notre réponse.
                            </p>
                        </Col>
                    </Row>
                </Container>

                <Footer />

            </div>

        </>
    );
}

export default Mentionslegales;