import React, { useEffect, useState } from 'react';
import Loading from './Loading';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { initializeApp } from "firebase/app";

function Test() {
    const [storage, setStorage] = useState([]);
    const [photo1,setPhoto1] = useState(null);
    const [preview1,setPreview1] = useState(null);
    const [loader1,setLoader1] = useState(false);
    const [urlFirebasep1, setUrlFirebasep1] = useState();
    const [erreurUploadp1, setErreurUploadp1] = useState(false);


    //firebase pour upload diag
    const firebaseConfig = {
        apiKey: "AIzaSyBx-T0tuEcSlSFsPF1zyeYQo3pZG-zytz8",
        authDomain: "clotere-33ee1.firebaseapp.com",
        projectId: "clotere-33ee1",
        storageBucket: "clotere-33ee1.appspot.com",
        messagingSenderId: "488961682641",
        appId: "1:488961682641:web:b89810aa37cda07d7169f0"
    };
    var appFirebase = null;
    
    //firebase initialisation
    useEffect(() => {
        const fetchData = async () => {
            appFirebase = initializeApp(firebaseConfig);
            setStorage(getStorage(appFirebase));
        }
        fetchData()
    }, []);

    //upload photo 1
    const onUploadPhoto1 = (event) => {
        //controle 
        const fileSize = event.target.files[0].size;
        const fileMb = fileSize / 10024 ** 2;
       
        if (fileMb >= 2) { // 5 MB in bytes
            alert('Votre fichier est trop volumineux. Taille maximale 5MB.');
            event.target.value = ''; // clear the file input
        } else{
            setLoader1(true);
            setPreview1(URL.createObjectURL(event.target.files[0]));

            const handleUpload1 = async () => {
                try {
                    // Upload du fichier sur firestore le temps de l'upload
                    const storageRef = ref(storage, event.target.files[0].name);
                    //console.log("storageRef",storageRef);

                    uploadBytes(storageRef, event.target.files[0])
                        .then((snapshot) => {
                            getDownloadURL(snapshot.ref)
                                .then((url) => {
                                    console.log("firebase url p1",url);
                                    setUrlFirebasep1(url);
                                    setPhoto1(event.target.files[0]);
                                    setLoader1(false);
                                    console.log("p1", event.target.files);
                                })
                                .catch((error) => {
                                    console.log("erreur url", error);
                                    setErreurUploadp1(true);
                                    setLoader1(false);
                                });
                        })
                        .catch((error) => {
                            console.log("erreur firebase", error);
                            setErreurUploadp1(true);
                            setLoader1(false);
                        })

                }
                catch (e) {
                    //console.log("Erreur lors de l'upload de votre document.",e)
                }
            }
            handleUpload1();

            
        }
    }

    return (
  
            <aside className='flex flex-wrap flex-row gap-3'>
                <label class="flex my-2 w-[300px]  h-[300px] rounded-md border-2 border-slate-200 flex-column justify-center items-center overflow-hidden">
                    <span class="mb-2">Photo 1</span>
                    {photo1 === null  &&
                    <>
                    <span className='flex w-content px-3 py-2 text-sm  bg-indigo-100 text-indigo-700 font-bold rounded-full'> + Ajouter
                    <input onChange={onUploadPhoto1} accept="image/png, image/jpeg" type="file" class="hidden"/>
                     </span>
                    </>
                    }
                    {loader1 === true && <><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="animate-spin h-5 w-5">
                        <path fillRule="evenodd" d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z" clipRule="evenodd" />
                        </svg>
                        </>}
                    {photo1 !== null && <>
                        <img className='w-auto max-w-[90%] h-[200px]' src={preview1}/>
                        <a href='#' onClick={() => { setPhoto1(null); }} className='text-red-400 no-underline text-sm mt-2 flex items-center'><XCircleIcon className='mr-2 w-6 h-6'/>Supprimer</a>
                    </>}
                </label>
            </aside>
    );
}

export default Test;