import React, { useState, useEffect } from "react";
import { useMemberstack, } from "@memberstack/react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { Widget } from '@typeform/embed-react';
import { useParams } from "react-router";
import { NavbarOnboard } from '../NavbarOnboard';
import { CheckIcon} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const WidgetTypeform = styled(Widget)`
width:100%;
height:80%;
`;

function OnboardDone(args, props) {
    const memberstack = useMemberstack();
    const [member, setMember] = useState(null);
    const navigate = useNavigate();
    const { type } = useParams();
    console.log("type", type);

    //redirect if already logged
    useEffect(() => {
        memberstack.getCurrentMember()
            .then(({ data: member }) => setMember(member))
    }, []);

    //console.log("member",member)

    return (
        <>
            <div className="bg-gradient-to-t from-teal-100 to-teal-50 min-h-px h-screen ">
                <NavbarOnboard />
                <div className="flex justify-center">
                    <div class="max-w-lg sm:min-h-screen">
                    <div className="bg-white  p-24 rounded-lg">
                        <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                            <CheckIcon aria-hidden="true" className="size-6 text-green-600" />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                            <h2 as="h3" className=" text-2xl/9 font-bold tracking-tight text-gray-900">
                                Félicitations votre procédure chez le notaire va commencer.
                            </h2>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Vos informations sont en cours de vérification. Vous pouvez accéder à votre espace client et commencer à ajouter vos documents.
                                </p>
                                <Link to="/connexion" className="btn-indigo no-underline">Accéder à mon espace client</Link>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>




            </div>
        </>
    );
}

export default OnboardDone;