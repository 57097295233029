import React from 'react';
import {  Row, Col } from "reactstrap";
import { Container } from "./Container";


function FindePage(args) {
    return (
        <>
            <Container>
                <Row>

                    <Col md="12" className='mt-5'>
                        <h3 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Comment <strong>trouver un notaire</strong> à {args.ville} ?</h3>
                        <p className="mt-6 text-xl leading-8">
                            Pour trouver un notaire à {args.ville}, vous pouvez consulter <a href="https://clotere.fr/fr/notaires">notre annuaire officiel des notaires</a>  de {args.ville}, qui offre un annuaire en ligne permettant de rechercher des notaires.Vous pouvez également filtrer ces notaires et choisir un notaire à {args.ville} qui vous offre un service de gestion de dossier en ligne. Il est conseillé de lire les avis clients et de comparer les services proposés avant de prendre rendez-vous. Enfin, demander des recommandations à des amis ou des collègues qui ont récemment utilisé des services notariaux peut également s'avérer utile.
                        </p>


                    </Col>
                    <Col md="12" className='mt-5'>
                        <h3 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Comprendre vos <strong>frais de notaire</strong> à {args.ville} </h3>
                        <p className="mt-6 text-xl leading-8">

                            Les frais de notaire à {args.ville}, à la charge de l’acheteur du bien immobilier, comprennent 3 éléments : Les droits de mutation ou d’enregistrement (80% du montant total), Les frais de débours (10% du montant). Les émoluments du notaire (10% du montant) .<br />
                            Retrouvez le détails de ces frais dans notre article dédié <a href='https://clotere.framer.website/blog/quels-sont-les-frais-%C3%A0-pr%C3%A9voir-lors-de-la-vente-d-un-bien-immobilier' target="blank">Comprendre les frais de notaire</a>

                        </p>
                    </Col>
                    <Col md="12" className='mt-5 mb-5'>
                        <h3 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Vous vendez ou achetez un bien à {args.ville} ? </h3>
                        <p className="mt-6 text-xl leading-8">
                            L'intervention d'un notaire à {args.ville} est essentielle lors d'un achat immobilier pour garantir la légalité et la sécurité de la transaction. Le notaire {args.ville} vérifie la validité des documents et s'assure que toutes les obligations légales sont remplies. Il enregistre également la transaction auprès des services de la publicité foncière, ce qui confère un caractère officiel à la propriété. Enfin, le notaire joue un rôle de conseiller impartial, aidant les parties à comprendre les implications juridiques et financières de l'achat..
                        </p>
                    </Col>
                </Row>
            </Container>
        </>

    );
}

export default FindePage;