import React from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
} from "reactstrap";
import {
  TitlePage,
  TitlePageApp,
  TitleSection,
} from "../../style/Layout";
import { CalendarDaysIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import TimelineListItem from "../../components/TimelineListItem";
import Articles from "../../components/Articles";
import ActePmaContenu from "../../components/ActePmaContenu";
import CardHelp from "../../components/CardHelp";
import styled from "styled-components";
import icnDocVente from "../../img/icn-doc-vente.svg";
import { PopupButton } from '@typeform/embed-react';
import CardNotaireDashboard from '../../components/CardNotaireDashboard';
import { CheckCircleIcon } from '@heroicons/react/20/solid'

function Dashboard(args) {
  //console.log("args dahboasrd pma", args);

  return (
    <>
      <Container>
        <Row className="d-flex align-self-start">
          <TitlePageApp>
            <Col md="7">
              <TitlePage>Bonjour {args.user.prenom} 👋</TitlePage>
              <p>
                Voici l'avancement de votre démarche chez le notaire
              </p>
            </Col>
          </TitlePageApp>

          <div className="mt-3">

            {/**Informations sur l'attribution de l'affaire au notaire */}
            <section>
            {args.pma.statut_marketplace_notaire === "en recherche de notaire" &&
              <>
                <div className="rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="shrink-0">
                    <CheckCircleIcon aria-hidden="true" className="size-5 text-yellow-400" />
                  </div>
                  <div className="ml-3">
                    
                    <div className="text-sm text-yellow-700">
                      <p className="m-0">Votre dossier est en cours de vérification, vous serez averti(e) lorsque votre notaire prendra en charge votre dossier</p>
                    </div>
                  </div>
                </div>
              </div>
              </>
            }

            {args.pma.statut_marketplace_notaire === "vérification avant attribution" &&
              <>
                <div className="rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="shrink-0">
                    <CheckCircleIcon aria-hidden="true" className="size-5 text-yellow-400" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">Votre notaire va prendre en charge votre dossier</h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p className="m-0">Votre dossier est en cours de vérification, vous serez averti(e) lorsque votre notaire prendra en charge votre dossier</p>
                    </div>
                  </div>
                </div>
              </div>
              </>
            }
            {args.pma.statut_marketplace_notaire === "notaire trouvé" &&
              <>
                <TitleSection>Votre notaire</TitleSection>
                <p className='mt-1 text-sm text-gray-500'><span role="img">👋</span> Votre dossier a bien été pris en charge par votre notaire, vous serez dorénavant notifié de toutes les évolutions de votre dossier.</p>
                <CardNotaireDashboard nom={args.pma.nom_from_notaire} prenom={args.pma.prenom_from_notaire} email={args.pma.email_from_notaire} telephone={args.pma.telephone_from_notaire} adresse={args.pma.adresse_from_notaire} cp={args.pma.code_postal_from_notaire} ville={args.pma.ville_from_notaire} photo={args.pma.photo_profil_from_notaire} />

              </>
            }
            </section>

            {/** action à mener**/}
            {args.action !== undefined && args.action.length > 0 &&
              <>
              <section className="mt-5">
                <TitleSection>Vos actions à mener</TitleSection>
                <div className="overflow-hidden rounded-lg bg-white shadow">
                  <div className="p-2">
                  <ListGroupActionAmener numbered>
                    {args.action.map((col, i) => (
                      <>
                        {args.action[i].fields.nom === "Questionnaire état civil" &&
                          <>
                            <TimelineListItem
                              type="etatCivilPma"
                              statut={args.action[i].fields.statut}
                              action={args.action[i]}
                            />
                          </>
                        }
                        {args.action[i].fields.nom === "Ajout document" &&
                          <>
                          <TimelineListItem
                              type="documentPma"
                              statut={args.action[i].fields.statut}
                            />
                          </>
                        }

                        {args.action[i].fields.nom === "Lecture de vos obligations légales" &&
                          <>
                            <TimelineListItem
                              type="lectureObligationsLegales"
                              statut={args.action[i].fields.statut}
                            />
                          </>
                        }

                      </>
                    ))}
                  </ListGroupActionAmener>
                  </div>
                  
                </div>
                </section>
              </>
            }

            {/**composant document de vente (ne s'affiche que si statut notaire trouvé) */}

            {args.evenement !== undefined &&
              <>
                <TitleSection className="mt-5">
                  Vos actes notariés
                </TitleSection>
                <Row>
                      <Col md="6" xs="12">
                        <div className="lg:col-start-3 lg:row-end-1">
                          <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                            <dl className="flex flex-wrap">
                              <div className="flex-auto pl-6 pt-6">
                                <img
                                  src={icnDocVente}
                                  alt="document-vente"
                                  className="mb-2 h-16"
                                />
                                <dd className="mt-1 text-xl font-semibold leading-6 text-gray-900">
                                  Reconnaissance conjointe anticipée (RCA)

                                </dd>
                              </div>
                              <div className="flex-none self-end px-6 pt-4">
                                {args.evenement[0].fields.etat === "pas fait" && <><dd className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">Non débuté</dd></>}
                                {args.evenement[0].fields.etat === "en cours" && <><dd className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">En cours</dd></>}
                                {args.evenement[0].fields.etat === "erreurs signalées" && <><dd className="inline-flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20">Erreurs signalées</dd></>}
                                {args.evenement[0].fields.etat === "fait" && <><dd className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Rédigé</dd></>}


                              </div>
                              <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                                <dt className="flex-none">
                                  <span className="sr-only">Client</span>
                                  <InformationCircleIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                                </dt>

                                {args.evenement[0].fields.etat === "pas fait" &&
                                  <>
                                    <div className="flex flex-column">
                                      <dd className="text-sm font-medium leading-6 text-gray-500">Non débuté</dd>
                                    </div>
                                  </>}

                                {args.evenement[0].fields.etat === "fait" &&
                                  <>
                                    <div className="flex flex-column">
                                      <dd className="text-sm font-medium leading-6 text-gray-900">Votre document est prêt 🥳 !</dd>
                                      <a href={`/app/relecture/${args.evenement[0].id}`} className="btn-main no-underline w-content">Relire le document</a>
                                    </div>
                                  </>}
                                {args.evenement[0].fields.etat === "erreurs signalées" &&
                                  <>
                                    <div className="flex flex-column">
                                      <dd className="text-sm font-medium leading-6 text-gray-900">Des erreurs ont été signalées sur ce document.</dd>
                                      <a href={`/app/relecture/${args.evenement[0].id}`} className="btn-main no-underline w-content">Relire le document</a>
                                    </div>
                                  </>}
                              </div>
                              <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                <dt className="flex-none">
                                  <CalendarDaysIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                                </dt>
                                {args.evenement[0].fields.statut_from_rdv[0] === "a programmer" && <dd className="text-sm leading-6 text-gray-500">Aucun rendez-vous prévu pour le moment</dd>}
                                {args.evenement[0].fields.statut_from_rdv[0] === "en cours de prog" &&
                                  <div className="flex flex-column">
                                    <dd className="text-sm leading-6 text-gray-900"> En cours de programmation</dd>
                                    <PopupButton
                                      id={args.evenement[0].fields.lien_typeform_reservtion[0]}
                                      size={100}
                                      hidden={{ id_user: args.user.airtable_id, nom_transaction: args.transaction.id }}
                                      autoClose={false}
                                      style={{ border: '0', background: 'none' }}
                                    >
                                      <button className="btn-main" >Indiquez vos disponibilités</button>
                                    </PopupButton>
                                  </div>
                                }
                                {args.evenement[0].fields.statut_from_rdv[0] === "programme" &&
                                  <>
                                    <div className="flex flex-column">
                                      <dd className="text-sm leading-6 text-gray-900"> Rendez-vous prévu le :</dd>
                                      <dd className="inline-flex items-center rounded-md bg-emerald-50 px-2 py-1 text-md font-bold text-emerald-700 ring-1 ring-inset ring-emerald-600/20">{args.evenement[0].fields.date_from_rdv[0]}</dd>
                                    </div>
                                  </>
                                }


                                {args.evenement[0].fields.etat === "information(s) manquante(s)" ? (<>Des informations sont manquantes</>) : (<>{" "}</>)}
                                {args.evenement[0].fields.etat === "a signer" ? (<>Votre document est prêt à être signé !</>) : (<>{" "}</>)}

                              </div>
                            </dl>
                          </div>
                          
                        </div>
                        
                      </Col>
                      <Col xs="12" md="6">
                      <ActePmaContenu />
                      </Col>

                </Row>
              </>
            }

            <TitleSection className="mt-5">Nos guides et ressources pour vous aider à comprendre 🔥 <br /></TitleSection>
            <Articles type="pma" />
            <Row>
              <Col md="12" align="center" className="mt-4 mb-4"><a className="btn-main no-underline" color="primary" href="https://clotere.framer.website/?utm_source=clotere" target="blank">Tous nos articles</a></Col></Row>



            <CardHelp email={args.user.email} />
          </div>

        </Row>
      </Container>
    </>
  );

}

const ListGroupActionAmener = styled(ListGroup)`
  border-radius: 0;
  border: 0;
  .list-group-item {
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    &:before {
      content: counters(section, "") "";
      counter-increment: section;
      display: inline;
      border: 1px solid #ddd;
      font-weight: 500;
      border-radius: 100px;
      padding: 10px 17px;
      margin-right: 30px;
    }
    &.done {
      &:before {
        content: "✔️";
      }
    }
    h4 {
      color: #1d2b28;
    }
    color: #84847c;
  }
  @media all and (max-width: 768px) {
    .list-group-item {
      flex-direction: column;
      &:before {
        margin-bottom: 1rem;
      }
    }
  }
`;






export default Dashboard;
