import React, { useState } from "react";
/** images */
//component 
import CardAppartement from "./CardAppartement";



function Listing(args) {

    return (
        <>
            <div className="container relativemt-16">
                <div className="grid grid-cols-1 pb-8 text-left">
                    <span className="inline-flex w-fit items-center rounded-full bg-emerald-50 px-2 py-1 text-sm font-bold text-emerald-700 ring-1 ring-inset ring-emerald-700/10">
                        Dernières annonces
                    </span>
                    <h2 className="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                        {args.title}
                    </h2>
                    <p className="m-0 text-slate-400 max-w-xl">Lorem ipsum pour le seo sur les mots clés immobilier et particulier </p>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
                    {/** card */}
                    <CardAppartement /><CardAppartement /><CardAppartement /><CardAppartement /><CardAppartement /><CardAppartement />
                </div>
            </div>
        </>
    );
}

export default Listing;