import React, { useState, useEffect } from "react";
import { useMemberstack, } from "@memberstack/react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { Widget } from '@typeform/embed-react';
import { useParams } from "react-router";
import { NavbarOnboard } from '../NavbarOnboard';
import { CheckIcon, PhoneIcon } from "@heroicons/react/20/solid";
import Maitre from "../img/maitre-philippe-vesse.jpeg";
import FormContratMariage from "../components/FormContratMariage";
import FormPma from "../components/FormPma";

const WidgetTypeform = styled(Widget)`
width:100%;
height:80%;
`;

function Onboard(args, props) {
    const memberstack = useMemberstack();
    const [member, setMember] = useState(null);
    const navigate = useNavigate();
    const { type } = useParams();
    console.log("type", type);

    //redirect if already logged
    useEffect(() => {
        memberstack.getCurrentMember()
            .then(({ data: member }) => setMember(member))
    }, []);

    //console.log("member",member)

    return (
        <>
            <div className="bg-gradient-to-t from-teal-100 to-teal-50 min-h-px h-screen ">
                <NavbarOnboard />
                <div className="container ">
                    <div class="flex flex-wrap">
                        <div class="w-full md:w-1/3">
                            <p className="text-2xl font-bold sm:mt-14 mt-8">Bonjour 👋</p>
                            <p className="text-lg">Merci de nous faire confiance, votre notaire va prendre en charge votre affaire <b>Ref 6577</b></p>
                            {/** card notaire  */}
                            <div
                                className="mt-2 mb-2 sm:mt-5 relative flex items-center space-x-3 rounded-lg "
                            >
                                <div className="shrink-0">
                                    <img alt="Votre notaire" src={Maitre} className="size-16 sm:size-24 rounded-full" />
                                </div>
                                <div className="min-w-0 flex-1">
                                    <span aria-hidden="true" className="absolute inset-0" />
                                    <p className="text-xl font-medium text-gray-900 no-underline m-0">Philippe Vesse</p>
                                    <span className="inline-flex items-center rounded-md bg-indigo-800 px-2 py-1 text-xs font-medium text-white">
                                        <CheckIcon className="size-4" /> Notaire recommandé
                                    </span>
                                </div>
                            </div>


                            {/**bloc aide */}
                            <div className="mt-60 hidden sm:block">
                                <p className="m-0">Besoin d'aide ?</p>
                                <span className="inline-flex items-center rounded-full bg-emerald-200 px-2 py-2 text-sm font-medium text-emerald-600">
                                    <PhoneIcon className="size-3" /> <a href="tel:0176390060" className="text-emerald-600">01 76 39 00 60</a> (appel gratuit)
                                </span>
                            </div>
                        </div>
                        <div class="w-full md:w-2/3 h-full">
                            {type === "donation" && (
                                <WidgetTypeform id="Vtk0ABOY" style={{ height: "100vh" }}  />
                            )}
                            {type === "achat" && (
                                <WidgetTypeform id="JM2MTsDD" style={{ height: "100vh" }} />
                            )}
                            {type === "pma" && (
                                <div className="bg-white p-3 sm:p-10 mt-10 sm:mt-0">
                                <FormPma />
                                </div>
                            )}
                            {type === "contrat-mariage" && (
                                <div className="bg-white p-3 sm:p-10 mt-10 sm:mt-0">
                                <FormContratMariage />
                                </div>
                            )}
                        </div>
                    </div>



                </div>
            </div>
        </>
    );
}

export default Onboard;