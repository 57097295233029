import React, { useState } from "react";
/** images */
import { ArrowsPointingOutIcon, HomeIcon, InformationCircleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";




function CardAppartement() {

    return (
        <>
            {/** card */}
            <div className="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-xl dark:hover:shadow-xl dark:shadow-gray-700 dark:hover:shadow-gray-700 overflow-hidden ease-in-out duration-500">
                <div className="relative">
                    <img src="https://shreethemes.in/hously/landing/assets/images/property/1.jpg" alt="" />
                    <div className="absolute top-4 end-4">
                        <span className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-sm font-bold text-purple-700 ring-1 ring-inset ring-purple-700/10">
                            Appartement
                        </span>
                    </div>
                </div>
                <div className="p-6">
                    <p className=" text-right text-3xl text-black font-extrabold"> 890 000€</p>
                    <div className="pb-6">
                        <a href="#" className="text-lg text-black no-underline font-bold hover:text-emerald-600  ease-in-out duration-500">Maison 5 pièces à vendre - 112 m² - Saint-Orens</a>
                        <p className="text-base text-slate-800">Maison année 1970 - Dépendance aménageable - Terrain 1160m²</p>
                    </div>
                    <ul className="py-2 px-0 border-y border-slate-100 dark:border-gray-800 flex items-center justify-start list-none">
                        <li className="flex items-center  justify-start basis-1/3">
                            <ArrowsPointingOutIcon className="text-emerald-300 mr-1 size-6" />
                            <span>130 m2</span>
                        </li>
                        <li className="flex items-center basis-1/3 justify-start">
                            <HomeIcon className="text-emerald-300 mr-1 size-6" />
                            <span>2 chambres</span>
                        </li>
                        <li className="flex items-center basis-1/3 justify-start">
                            <InformationCircleIcon className="text-emerald-300 mr-1 size-6" />
                            <span>3e étage</span>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default CardAppartement;